import dayjs from 'dayjs';

export const getIntervalOptions = () => [
  {
    label: 'Year to date',
    value: dayjs().diff(dayjs().startOf('year'), 'day'),
  },
  {
    label: 'Last 7 days',
    value: 7,
  },
  {
    label: 'Last 30 days',
    value: 30,
  },
  {
    label: 'Last 3 months',
    value: dayjs().diff(dayjs().subtract(3, 'month'), 'day'),
  },
  {
    label: 'Last 6 months',
    value: dayjs().diff(dayjs().subtract(6, 'month'), 'day'),
  },
  {
    label: 'Last 12 months',
    value: dayjs().diff(dayjs().subtract(12, 'month'), 'day'),
  },
];
