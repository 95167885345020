import {
  BuyerOfferAnalysisFragment,
  ContingencyType,
  FinancingType,
  MlsListingFragment,
  PartyFragment,
} from '@client/graphql/__generated__/types';
import isNil from 'lodash/isNil';

export interface AgentContingencyMap {
  [contingency: string]: {
    value?: boolean | null;
    notes?: string | null;
  };
}

export interface AgentKeyTermMap {
  [keyTerm: string]: {
    value?: number | null;
    notes?: string | null;
  };
}

export const getTitle = (title?: string, mlsListing?: MlsListingFragment) => {
  if (!title) {
    return `Offer draft${
      mlsListing?.address?.full ? ` for ${mlsListing?.address?.full}` : ''
    }`;
  }

  return title;
};

export const getInitialContingencyValue = (
  contingencyType: ContingencyType,
  party?: PartyFragment
) => {
  if (isNil(party)) return false;

  if (contingencyType === ContingencyType.FINANCING) {
    if (
      !isNil(party.partyFinancing?.financingType) &&
      party?.partyFinancing?.financingType !== FinancingType.CASH
    ) {
      return true;
    } else {
      return false;
    }
  }

  // TODO: add other contingencies when we start supporting them

  return false;
};

export const transformAgentOfferRecs = (
  contingencies: AgentContingencyMap,
  keyTerms: AgentKeyTermMap
) => {
  const transform = (
    keyTermsOrContingencies: AgentKeyTermMap | AgentContingencyMap
  ) => {
    return Object.entries(keyTermsOrContingencies).map(
      ([key, value]: [
        key: string,
        value: { value: boolean | number | null; notes: string | null },
      ]) => ({
        type: key,
        value: value.value,
        notes: value.notes,
      })
    );
  };

  return {
    contingencies: transform(contingencies),
    keyTerms: transform(keyTerms),
  };
};

export const mapAgentOfferRecs = (
  buyerOfferAnalysis?: BuyerOfferAnalysisFragment
) => {
  const agentOfferRecs = buyerOfferAnalysis?.agentOfferRecs;
  const contingencies =
    (buyerOfferAnalysis?.indigoOfferRecs?.contingencies?.reduce(
      (acc, contingency) => {
        const agentContingency = agentOfferRecs?.contingencies?.find(
          (c) => c.type === contingency.type
        );
        const value = !isNil(agentContingency?.value)
          ? agentContingency?.value
          : getInitialContingencyValue(
              contingency.type,
              buyerOfferAnalysis?.party
            );

        return {
          ...acc,
          [contingency.type]: {
            value,
            notes: agentContingency?.notes,
          },
        };
      },
      {}
    ) || {}) as AgentContingencyMap;

  const keyTerms = (agentOfferRecs?.keyTerms?.reduce(
    (acc, keyTerm) => ({
      ...acc,
      [keyTerm.type]: {
        value: keyTerm.value,
        notes: keyTerm.notes,
      },
    }),
    {}
  ) || {}) as AgentKeyTermMap;

  return { contingencies, keyTerms };
};
