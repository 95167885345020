import {
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  IconButton,
  Input,
  Text,
} from '@chakra-ui/react';
import { ArrowLeftIcon, CheckCheckIcon, PencilIcon } from 'lucide-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { HEADER_HEIGHT } from './constants';
import { useBuyerOfferAnalysisContext } from './Context/BuyerOfferAnalysisContext';

export const BuyerOfferAnalysisHeader = () => {
  const { isChangesSaved, title, updateTitle, setTitle } =
    useBuyerOfferAnalysisContext();
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const onBlur = () => {
    updateTitle(title);
    setIsEditingTitle(false);
  };

  return (
    <Flex
      alignItems="center"
      backdropFilter="blur(4px)"
      bg="indigo.700"
      borderBottom="1px solid"
      borderBottomColor="whiteAlpha.200"
      height={HEADER_HEIGHT}
      justifyContent="space-between"
      position="sticky"
      pr={4}
      top={0}
      width="100%"
      zIndex={1}
    >
      <Flex alignItems="center" height="100%">
        <Flex alignItems="center" height="100%" px={5}>
          <IconButton
            aria-label="Back"
            as={Link}
            color="white"
            icon={<ArrowLeftIcon />}
            size="sm"
            to="/"
            variant="ghost"
          />
        </Flex>
        <Divider height="100%" orientation="vertical" />
        <Flex alignItems="center" gap={2} height="100%">
          {isEditingTitle ? (
            <Input
              autoFocus
              value={title}
              width="300px"
              onBlur={onBlur}
              onChange={(e) => setTitle(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onBlur();
                }
              }}
            />
          ) : (
            <>
              <Heading color="white" fontWeight="medium" pl={4} size="headline">
                {title}
              </Heading>
              <IconButton
                aria-label="Edit Offer Draft Title"
                boxSize={8}
                color="whiteAlpha.600"
                icon={<PencilIcon size={16} />}
                size="sm"
                variant="ghost"
                onClick={() => setIsEditingTitle(true)}
              />
            </>
          )}
        </Flex>
      </Flex>

      <Flex alignItems="center" gap={4}>
        <Flex alignItems="center" gap={2}>
          {isChangesSaved && (
            <Icon as={CheckCheckIcon} color="green.500" size={16} />
          )}
          <Text color="whiteAlpha.600">
            {isChangesSaved ? 'All changes saved' : 'Changes not saved'}
          </Text>
        </Flex>
        <Button bg="blueGradient" size="xs">
          Share with buyer
        </Button>
      </Flex>
    </Flex>
  );
};
