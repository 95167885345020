import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { KeyTermType } from '@client/graphql/__generated__/types';
import { PercentageTag } from '~/common/components/PercentageTag';
import { formatToCurrency } from '~/services/document/utils/number';
import { useBuyerOfferAnalysisContext } from '../Context/BuyerOfferAnalysisContext';

export const PurchasePriceCard = () => {
  const { listing, agentKeyTerms } = useBuyerOfferAnalysisContext();

  if (!listing) {
    return <Skeleton flexBasis={0} flexGrow={1} height="250px" />;
  }

  const purchasePrice = agentKeyTerms[KeyTermType.PURCHASE_PRICE]?.value || 0;

  const formattedAgentPurchasePrice = formatToCurrency(purchasePrice);
  const formattedListPrice = formatToCurrency(listing.mlsListing?.listPrice);

  return (
    <Card flexBasis={0} flexGrow={1} height="250px">
      <CardHeader>
        <Text color="whiteAlpha.600" textAlign="center">
          Purchase Price
        </Text>
      </CardHeader>
      <CardBody>
        <Flex direction="column" gap={2} width="100%">
          <Text fontSize="40px" fontWeight="medium" textAlign="center">
            {formattedAgentPurchasePrice}
          </Text>
          <Flex justifyContent="center">
            {listing.mlsListing?.listPrice && (
              <PercentageTag
                compareValue={listing.mlsListing?.listPrice}
                value={purchasePrice}
              />
            )}
          </Flex>
        </Flex>
      </CardBody>
      <CardFooter>
        <Flex alignItems="center" gap={2} justifyContent="center" width="100%">
          <Text color="whiteAlpha.600" textTransform="uppercase">
            List
          </Text>
          <Text fontWeight="medium">{formattedListPrice}</Text>
        </Flex>
      </CardFooter>
    </Card>
  );
};
