import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Heading,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { PencilIcon, Trash2Icon } from 'lucide-react';
import { useBuyerOfferAnalysisContext } from './Context/BuyerOfferAnalysisContext';

export const AgentNotesCard = () => {
  const {
    agentNotes,
    isEditingAgentNotes,
    setIsEditingAgentNotes,
    setAgentNotes,
    updateAgentNotes,
  } = useBuyerOfferAnalysisContext();

  const onBlur = (notes?: string) => {
    updateAgentNotes(notes);
    setIsEditingAgentNotes(false);
  };

  if (!agentNotes && !isEditingAgentNotes) {
    return null;
  }

  return (
    <Card width="100%">
      <CardHeader>
        <Flex alignItems="center" justifyContent="space-between">
          <Heading
            color="whiteAlpha.600"
            fontSize="sm"
            fontWeight="medium"
            size="smallUppercase"
          >
            Note to buyer
          </Heading>
          <Flex gap={2}>
            {!isEditingAgentNotes && (
              <>
                <Button
                  borderRadius="48px"
                  color="whiteAlpha.600"
                  leftIcon={<PencilIcon size={14} />}
                  size="xs"
                  variant="outline"
                  onClick={() => {
                    setIsEditingAgentNotes(true);
                  }}
                >
                  Edit
                </Button>
                <Button
                  borderRadius="48px"
                  color="whiteAlpha.600"
                  leftIcon={<Trash2Icon size={14} />}
                  size="xs"
                  variant="outline"
                  onClick={() => {
                    setAgentNotes(undefined);
                    onBlur(undefined);
                  }}
                >
                  Remove
                </Button>
              </>
            )}
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody>
        {isEditingAgentNotes ? (
          <Textarea
            autoFocus
            value={agentNotes}
            onBlur={() => onBlur(agentNotes)}
            onChange={(e) => setAgentNotes(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                onBlur(agentNotes);
              }
            }}
          />
        ) : (
          <Text whiteSpace="pre-wrap">{agentNotes}</Text>
        )}
      </CardBody>
    </Card>
  );
};
