import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  VStack,
} from '@chakra-ui/react';
import { MlsListingObject } from '@client/graphql/__generated__/types';
import { useRef } from 'react';
import { ComparablesContainer } from '~/apps/consumer/components/Comparables/styledComponents';
import { ComparableColumn } from '~/apps/consumer/components/Listings/Comparables/ComparableColumns/ComparableColumn';
import { DraggableComparableColumns } from '~/apps/consumer/components/Listings/Comparables/DraggableComparableColumns';
import { ExploreProvider } from '~/apps/consumer/components/MapSearch/ExploreContext';
import { useBuyerOfferAnalysisContext } from '../../Context/BuyerOfferAnalysisContext';
import { EditCompsMap } from './EditCompsMap/EditCompsMap';
import { EditCompsMapFilters } from './EditCompsMap/EditCompsMapFilters';
import { EditCompsMapSidebar } from './EditCompsMap/EditCompsMapSidebar/EditCompsMapSidebar';

export const EditCompsModal = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const geocoderInputRef = useRef<HTMLInputElement>(null);

  const {
    listing,
    comparableSet,
    comps,
    setComps,
    isEditCompsModalOpen,
    onEditCompsModalClose,
    setIsCompsUpdated,
    buyerOfferAnalysisLoading,
  } = useBuyerOfferAnalysisContext();

  const mainListing = listing;

  const onAdd = () => {
    geocoderInputRef.current?.focus();
  };

  if (!mainListing) {
    if (!buyerOfferAnalysisLoading) {
      onEditCompsModalClose();
    }

    return null;
  }

  const onAdjustmentsUpdate = () => {
    setIsCompsUpdated(true);
  };

  return (
    <ExploreProvider showClosedStatus>
      <Modal
        isOpen={isEditCompsModalOpen}
        scrollBehavior="inside"
        size="full"
        trapFocus={false}
        onClose={onEditCompsModalClose}
      >
        <ModalContent>
          <ModalHeader>Edit Comps</ModalHeader>
          <ModalCloseButton />
          <ModalBody ref={containerRef} px={10}>
            <VStack gap={4} width="100%">
              <Flex
                direction="column"
                height="80vh"
                position="relative"
                width="100%"
              >
                <EditCompsMapFilters geocoderInputRef={geocoderInputRef} />
                <EditCompsMap
                  comparableSet={comparableSet}
                  mainListing={mainListing}
                />
                <EditCompsMapSidebar
                  containerRef={containerRef}
                  onAdd={onAdd}
                />
              </Flex>
              <ComparablesContainer width="100%">
                <ComparableColumn
                  isMainListing
                  isEditMode={true}
                  mainMlsListing={mainListing?.mlsListing as MlsListingObject}
                  mlsListing={mainListing?.mlsListing as MlsListingObject}
                  showAdjustments={true}
                  showNotes={true}
                />
                <DraggableComparableColumns
                  comparableSet={comparableSet}
                  comparables={comps}
                  isEditMode={true}
                  mainMlsListing={mainListing?.mlsListing as MlsListingObject}
                  setComparables={setComps}
                  showAdjustments={true}
                  showNotes={true}
                  onAdjustmentsUpdate={onAdjustmentsUpdate}
                />
              </ComparablesContainer>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ExploreProvider>
  );
};
