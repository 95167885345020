// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSumAll = <T extends Record<string, any>>(data?: T[]) => {
  return data?.reduce(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (acc, transaction) => {
      if (Object.keys(acc).length === 0) {
        return { ...transaction };
      }

      Object.keys(acc).forEach((key) => {
        if (typeof acc[key] === 'number') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/restrict-plus-operands
          Object.assign(acc, { [key]: acc[key] + transaction[key] });
        }
      });

      return acc;
    },
    {} as T
  );
};
