import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { KeyTermType } from '@client/graphql/__generated__/types';
import { useState } from 'react';
import { useBuyerOfferAnalysisContext } from '../Context/BuyerOfferAnalysisContext';
import { KeyTermsRow } from './KeyTermsRow';

export const KeyTermsTable = () => {
  const [expandedRow, setExpandedRow] = useState<KeyTermType | null>(null);
  const {
    offerRecs,
    offerRecsLoading,
    agentKeyTerms,
    setAgentKeyTerms,
    buyerOfferAnalysis,
  } = useBuyerOfferAnalysisContext();
  const keyTerms = offerRecs?.keyTerms;
  const buyersAgentUser = buyerOfferAnalysis?.buyersAgent?.user;

  if (!keyTerms || offerRecsLoading) {
    return <Skeleton height="500px" width="100%" />;
  }

  return (
    <Card width="100%">
      <CardHeader
        borderBottom="1px solid"
        borderBottomColor="whiteAlpha.200"
        pb={4}
      >
        <Flex alignItems="center" gap={6} justifyContent="space-between">
          <Flex basis={0} flexGrow={1}>
            <Text color="whiteAlpha.600">Key Terms</Text>
          </Flex>
          <Flex basis={0} flexGrow={2}>
            <Text color="whiteAlpha.600">Market Data</Text>
          </Flex>
          <Flex basis={0} flexGrow={2}>
            <Text color="whiteAlpha.600">Your Recommendations</Text>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody p={0}>
        {keyTerms?.map((keyTerm) => {
          const keyTermValue = agentKeyTerms[keyTerm.type]?.value;
          const keyTermNotes = agentKeyTerms[keyTerm.type]?.notes;
          const onUpdateValue = (value?: number | null) => {
            setAgentKeyTerms({
              ...agentKeyTerms,
              [keyTerm.type]: { ...agentKeyTerms[keyTerm.type], value },
            });
          };
          const onUpdateNotes = (notes: string | null) => {
            setAgentKeyTerms({
              ...agentKeyTerms,
              [keyTerm.type]: { ...agentKeyTerms[keyTerm.type], notes },
            });
          };
          const hasError = keyTerm.__typename === 'KeyTermError';
          if (hasError) {
            return (
              <KeyTermsRow
                key={keyTerm.type}
                buyersAgentUser={buyersAgentUser}
                errorMessage={keyTerm.reason}
                isExpanded={expandedRow === keyTerm.type}
                label={keyTerm.type?.split('_').join(' ').toLowerCase()}
                notes={keyTermNotes}
                row={keyTerm.type}
                setExpandedRow={setExpandedRow}
                unit={keyTerm.unit}
                value={keyTermValue}
                onUpdateNotes={onUpdateNotes}
                onUpdateValue={onUpdateValue}
              />
            );
          }

          const confidence =
            keyTerm.context?.source.__typename === 'IndigoOffersSource'
              ? keyTerm.context?.source?.metadata?.confidence
              : undefined;

          const distanceMiles =
            keyTerm.context?.source.__typename === 'IndigoOffersSource'
              ? keyTerm.context?.source?.metadata?.distanceMiles
              : undefined;

          const timePeriodMonths =
            keyTerm.context?.source.__typename === 'IndigoOffersSource'
              ? keyTerm.context?.source?.metadata?.timePeriodMonths
              : undefined;

          return (
            <KeyTermsRow
              key={keyTerm.type}
              buyersAgentUser={buyersAgentUser}
              confidence={confidence}
              distanceMiles={distanceMiles}
              isExpanded={expandedRow === keyTerm.type}
              label={keyTerm.type?.split('_').join(' ').toLowerCase()}
              marketDataPercentages={keyTerm.context?.marketDataPercentages}
              notes={keyTermNotes}
              range={[
                keyTerm.context?.marketData?.low || 0,
                keyTerm.context?.marketData?.high || 0,
              ]}
              row={keyTerm.type}
              setExpandedRow={setExpandedRow}
              source={keyTerm.context?.source?.type}
              timePeriodMonths={timePeriodMonths}
              unit={keyTerm.unit}
              value={keyTermValue}
              onUpdateNotes={onUpdateNotes}
              onUpdateValue={onUpdateValue}
            />
          );
        })}
      </CardBody>
    </Card>
  );
};
