import {
  OrgTransactionStatsByZipCodeObject,
  PerAgentOfferAcceptRateFragment,
  PerAgentTransactionStatsFragment,
} from '@client/graphql/__generated__/types';
import { useMemo } from 'react';
import { formatPercent, formatPrice } from '~/common/utils/formatter';
import { average } from '~/common/utils/math';
import { useSumAll } from '../../../../../../common/hooks/useSumAll';

interface TopPerformers {
  buyingVolume?: PerAgentTransactionStatsFragment[];
  buyingHomes?: PerAgentTransactionStatsFragment[];
  offerAcceptRate?: PerAgentOfferAcceptRateFragment[];
}

export const usePerformanceMetrics = (
  transactions?: OrgTransactionStatsByZipCodeObject[],
  perAgentOfferAcceptRate?: PerAgentOfferAcceptRateFragment[],
  perAgentTransactionStats?: PerAgentTransactionStatsFragment[]
) => {
  const teamPerformance = useSumAll(transactions);
  const teamAcceptRate = average(
    perAgentOfferAcceptRate?.map((agent) => agent.offer_accept_rate ?? 0)
  );
  const sortedPerAgentOfferAcceptRate = useMemo(
    () =>
      Array.from(perAgentOfferAcceptRate || [])?.sort(
        (a, b) => b.total_volume - a.total_volume
      ),
    [perAgentOfferAcceptRate]
  );
  const sortedPerAgentTransactionStats = useMemo(
    () =>
      Array.from(perAgentTransactionStats || [])?.sort(
        (a, b) => b.total_volume - a.total_volume
      ),
    [perAgentTransactionStats]
  );
  const maxVolume = Math.max(
    ...(perAgentTransactionStats?.map((agent) => agent.total_volume) ?? [])
  );
  const maxHomes = Math.max(
    ...(perAgentTransactionStats?.map((agent) => agent.buy_side_homes_count) ??
      [])
  );
  const minHomes = Math.min(
    ...(perAgentTransactionStats?.map((agent) => agent.buy_side_homes_count) ??
      [])
  );
  const averageHomes = (maxHomes + minHomes) / 2;
  const filteredAgentsForOfferAcceptRate =
    sortedPerAgentOfferAcceptRate?.filter(
      (agent) =>
        agent?.offer_accept_rate &&
        agent?.accepted_offers >= Math.ceil(averageHomes)
    );
  const maxOfferAcceptRate = Math.max(
    ...(filteredAgentsForOfferAcceptRate?.map(
      (agent) => agent.offer_accept_rate ?? 0
    ) ?? [])
  );

  const topOfferAcceptRate = filteredAgentsForOfferAcceptRate?.reduce(
    (acc, agent) => {
      if (agent.offer_accept_rate === maxOfferAcceptRate) {
        if (!acc?.offerAcceptRate) {
          acc.offerAcceptRate = [agent];
        } else {
          acc.offerAcceptRate.push(agent);
        }
      }

      return acc;
    },
    {} as Pick<TopPerformers, 'offerAcceptRate'>
  );

  const topBuying = sortedPerAgentTransactionStats?.reduce(
    (acc, agent) => {
      if (maxVolume > 0 && agent.total_volume === maxVolume) {
        if (!acc?.buyingVolume) {
          acc.buyingVolume = [agent];
        } else {
          acc.buyingVolume.push(agent);
        }
      }

      if (maxHomes > 0 && agent.buy_side_homes_count === maxHomes) {
        if (!acc?.buyingHomes) {
          acc.buyingHomes = [agent];
        } else {
          acc.buyingHomes.push(agent);
        }
      }

      return acc;
    },
    {} as Omit<TopPerformers, 'offerAcceptRate'>
  );

  return {
    teamPerformance: [
      {
        title: 'Accepted Volume',
        value: formatPrice(teamPerformance?.total_volume ?? 0),
      },
      {
        title: 'Accepted Sides',
        value: teamPerformance?.total_homes_count,
      },
      {
        title: 'Offer Accept Rate',
        value:
          typeof teamAcceptRate === 'number'
            ? formatPercent(teamAcceptRate)
            : null,
      },
    ],
    topPerformers: [
      {
        title: 'Accepted Volume',
        agents: topBuying?.buyingVolume?.map((agent) => ({
          agent_name: agent.agent_name,
          value: formatPrice(agent.total_volume),
        })),
      },
      {
        title: 'Accepted Sides',
        agents: topBuying?.buyingHomes?.map((agent) => ({
          agent_name: agent.agent_name,
          value: `${agent.buy_side_homes_count} Offers`,
        })),
      },
      {
        title: 'Offer Accept Rate',
        agents: topOfferAcceptRate?.offerAcceptRate?.map((agent) => ({
          agent_name: agent.agent_name,
          value: formatPercent(agent.offer_accept_rate),
        })),
      },
    ],
  };
};
