import { Divider, Flex, Stack, Text } from '@chakra-ui/react';
import { useUpdateUserSettingsMutation } from '@client/graphql/__generated__/main-operations';
import {
  ChartNoAxesCombined,
  Gauge,
  LayoutDashboardIcon,
  MapIcon,
  SearchIcon,
  TrendingUp,
  UsersIcon,
} from 'lucide-react';
import { FC } from 'react';
import { useRegisteredRoles } from '~/apps/consumer/hooks/useRegisteredRoles';
import { useProdFeatureFlagEnabled } from '~/common/hooks/useProdFeatureFlagEnabled';
import { DocIcon, HomeIcon } from '~/common/icons';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { useFindPropertyEvents } from '../../pages/Buyers/hooks/useFindPropertyEvents';
import { FindPropertyModal } from '../MakeOffer/FindPropertyModal';
import { NavButton, NavButtonProps } from '../Navigation/NavButton';
import { OrganizationSelect } from '../Organization/OrganizationSelect';
import { SIDEBAR_WIDTH_EXPANDED } from '../PageDetailLayout/SidebarItem';
import { NavGroupLabel } from './NavGroupLabel';
import { NavItemAlerts } from './NavItemAlerts';

export const NavItems: FC<
  NavButtonProps & { setIsExpanded?: (expanded: boolean) => void }
> = ({ isMobile, isExpanded, setIsExpanded, onClick }) => {
  const { user, selectedOrganization, isSelectedOrgAdmin, organizations } =
    useAppContext();
  const { isRegisteredAgent } = useRegisteredRoles();
  const isTeamOfferDataEnabled = useProdFeatureFlagEnabled('team-offer-data');

  const { isFindPropertyModalOpen, setIsFindPropertyModalOpen, onSelect } =
    useFindPropertyEvents();

  const isBuyerAgencyAgreementEnabled = useProdFeatureFlagEnabled(
    'buyer-agency-agreement'
  );

  const [updateUserSettings] = useUpdateUserSettingsMutation();

  const iconSize = {
    width: '16px',
    height: '16px',
  };

  return (
    <Stack
      alignItems="flex-start"
      flexDir="column"
      height={{ base: '100vh', md: 'auto' }}
      justifyContent={{ base: 'flex-start', md: 'center' }}
      width="100%"
    >
      {!!selectedOrganization && (
        <>
          <Flex
            overflow="hidden"
            pt={2}
            px={4}
            visibility={isExpanded ? 'visible' : 'hidden'}
            width={SIDEBAR_WIDTH_EXPANDED}
          >
            <OrganizationSelect
              organizations={organizations}
              selectedOrganizationId={selectedOrganization.id || undefined}
              onChange={(value) =>
                void updateUserSettings({
                  variables: {
                    input: {
                      selectedOrganizationId: value,
                    },
                  },
                })
              }
            />
          </Flex>
          {isSelectedOrgAdmin && (
            <>
              <NavButton
                isExpanded={isExpanded}
                leftIcon={<LayoutDashboardIcon {...iconSize} />}
                to="/organizations/overview"
                onClick={onClick}
              >
                {isExpanded && <Text fontWeight={500}>Overview</Text>}
              </NavButton>
              {isTeamOfferDataEnabled && (
                <>
                  <NavButton
                    isExpanded={isExpanded}
                    leftIcon={<TrendingUp {...iconSize} />}
                    to="/organizations/performance"
                    onClick={onClick}
                  >
                    {isExpanded && <Text fontWeight={500}>Performance</Text>}
                  </NavButton>
                  <NavButton
                    isExpanded={isExpanded}
                    leftIcon={<ChartNoAxesCombined {...iconSize} />}
                    to="/organizations/offer-benchmarks"
                    onClick={onClick}
                  >
                    {isExpanded && <Text fontWeight={500}>Benchmarks</Text>}
                  </NavButton>
                </>
              )}
            </>
          )}
          <NavButton
            isExpanded={isExpanded}
            leftIcon={<UsersIcon {...iconSize} />}
            to="/organizations/admin"
            onClick={onClick}
          >
            {isExpanded && <Text fontWeight={500}>Admin</Text>}
          </NavButton>
        </>
      )}
      {(!isMobile || (isMobile && !!selectedOrganization)) && (
        <Divider
          borderColor="whiteAlpha.300"
          my={!!selectedOrganization ? 4 : 0}
          orientation="horizontal"
          width="100%"
        />
      )}
      {!!selectedOrganization && (
        <NavGroupLabel isExpanded={isExpanded}>Agent</NavGroupLabel>
      )}
      {isRegisteredAgent && isBuyerAgencyAgreementEnabled && (
        <NavButton
          isExpanded={isExpanded}
          leftIcon={<Gauge {...iconSize} />}
          to="/dashboard"
          onClick={onClick}
        >
          {isExpanded && <Text fontWeight={500}>Dashboard</Text>}
        </NavButton>
      )}
      <NavItemAlerts
        iconSize={iconSize}
        isExpanded={isExpanded}
        onClick={onClick}
      />
      {(user?.hasListings || isRegisteredAgent) && (
        <NavButton
          isExpanded={isExpanded}
          leftIcon={<HomeIcon {...iconSize} />}
          to="/listings"
          onClick={onClick}
        >
          {isExpanded && <Text fontWeight={500}>Listings</Text>}
        </NavButton>
      )}
      {isRegisteredAgent && (
        <NavButton
          isExpanded={isExpanded}
          leftIcon={<DocIcon {...iconSize} />}
          to="/buyers"
          onClick={onClick}
        >
          {isExpanded && <Text fontWeight={500}>Buyers</Text>}
        </NavButton>
      )}
      <NavButton
        isExpanded={isExpanded}
        leftIcon={<MapIcon {...iconSize} />}
        to="/map"
        onClick={onClick}
      >
        {isExpanded && (
          <Flex>
            <Text fontWeight={500}>Map Search</Text>
          </Flex>
        )}
      </NavButton>
      {isRegisteredAgent && (
        <NavButton
          isExpanded={isExpanded}
          leftIcon={<SearchIcon {...iconSize} />}
          onClick={() => setIsFindPropertyModalOpen(true)}
        >
          {isExpanded && <Text fontWeight={500}>Find Property</Text>}
        </NavButton>
      )}
      <FindPropertyModal
        isOpen={isFindPropertyModalOpen}
        onClose={() => setIsFindPropertyModalOpen(false)}
        onSelect={async (listing) => {
          await onSelect(listing);
          onClick?.();
          setIsExpanded?.(false);
        }}
      />
    </Stack>
  );
};
