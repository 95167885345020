import {
  Flex,
  Icon,
  NumberInput,
  NumberInputField,
  Text,
} from '@chakra-ui/react';
import {
  ConfidenceLevel,
  KeyTermType,
  KeyTermUnit,
  MarketDataPercentages,
  MarketDataSourceType,
  UserObject,
} from '@client/graphql/__generated__/types';
import isNil from 'lodash/isNil';
import { CircleAlertIcon } from 'lucide-react';
import { useRef } from 'react';
import { CurrencyInput } from '~/common/components/Inputs/CurrencyInput';
import { SignalStrength } from '~/common/icons/SignalIcon';
import { COLLAPSED_ROW_HEIGHT, EXPANDED_ROW_HEIGHT } from './constants';
import { KeyTermsRangeChart } from './KeyTermsRangeChart';
import { NotesPopover } from './NotesPopover';
import { SourceTags } from './SourceTags';
import { formatRangeValue, getDotColor, getMarketDataDisplay } from './utils';

interface KeyTermsRowProps {
  isExpanded: boolean;
  setExpandedRow: (row: KeyTermType | null) => void;
  label: string;
  range?: [number, number];
  row: KeyTermType;
  unit?: KeyTermUnit;
  source?: MarketDataSourceType;
  confidence?: ConfidenceLevel;
  distanceMiles?: number;
  timePeriodMonths?: number;
  errorMessage?: string;
  value?: number | null;
  notes?: string | null;
  buyersAgentUser?: Partial<Pick<UserObject, 'fullName' | 'profilePicture'>>;
  onUpdateValue: (value?: number | null) => void;
  onUpdateNotes: (notes?: string | null) => void;
  marketDataPercentages?: MarketDataPercentages;
}

export const KeyTermsRow = ({
  isExpanded,
  setExpandedRow,
  label,
  range,
  row,
  unit,
  source,
  confidence,
  distanceMiles,
  timePeriodMonths,
  errorMessage,
  value,
  notes,
  onUpdateValue,
  onUpdateNotes,
  buyersAgentUser,
  marketDataPercentages,
}: KeyTermsRowProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const isSingleValue = range && range[0] === range[1];

  const rangeLabel =
    range && unit
      ? isSingleValue
        ? formatRangeValue(range[0], unit)
        : `${formatRangeValue(range[0], unit)} - ${formatRangeValue(
            range[1],
            unit
          )}`
      : undefined;
  const sourceLabel = source?.toLowerCase()?.split('_')?.join(' ');
  const pctLabel = marketDataPercentages
    ? getMarketDataDisplay(marketDataPercentages, `have ${label.toLowerCase()}`)
    : null;

  const dotColor = getDotColor({ unit, value, range, type: row });

  return (
    <Flex
      ref={containerRef}
      borderBottom="1px solid"
      borderBottomColor="whiteAlpha.200"
      direction="column"
      justifyContent="center"
      px={4}
      tabIndex={0}
      onBlur={() => {
        setExpandedRow(null);
      }}
      onClick={() => {
        if (inputRef.current !== document.activeElement) {
          inputRef.current?.focus();
        }
      }}
      onFocus={() => {
        if (!isSingleValue && range) {
          setExpandedRow(row);
        }
      }}
      onMouseDown={() => {
        if (inputRef.current !== document.activeElement && !isExpanded) {
          inputRef.current?.focus();
        }
      }}
    >
      <Flex
        alignItems="center"
        gap={6}
        height={isExpanded ? EXPANDED_ROW_HEIGHT : COLLAPSED_ROW_HEIGHT}
        py={4}
        transition="height 0.3s ease-in-out"
      >
        <Flex flexBasis={0} flexGrow={1} height="100%">
          <Flex direction="column" height="100%" justifyContent="space-between">
            <Text fontWeight="medium" textTransform="capitalize">
              {label}
            </Text>
          </Flex>
        </Flex>
        <Flex flexBasis={0} flexGrow={4} height="100%">
          <Flex
            direction="column"
            height="100%"
            justifyContent="space-between"
            width="100%"
          >
            <Flex height="100%">
              <Flex flexBasis={0} flexGrow={2} height="100%">
                <Flex direction="column" gap={1}>
                  {pctLabel}
                  {!!range && (
                    <>
                      <Text
                        color={pctLabel ? 'whiteAlpha.600' : 'white'}
                        fontSize={pctLabel ? '16px' : '24px'}
                        fontWeight={pctLabel ? 'normal' : 'medium'}
                        lineHeight="1"
                      >
                        {rangeLabel}
                      </Text>
                      <SourceTags
                        confidence={confidence as unknown as SignalStrength}
                        distanceMiles={distanceMiles}
                        isExpanded={isExpanded}
                        sourceLabel={sourceLabel}
                        timePeriodMonths={timePeriodMonths}
                      />
                    </>
                  )}
                </Flex>
                {errorMessage && (
                  <Flex alignItems="center" gap={1}>
                    <Icon as={CircleAlertIcon} color="red.500" />
                    <Text>{errorMessage}</Text>
                  </Flex>
                )}
              </Flex>
              <Flex flexBasis={0} flexGrow={2} gap={4} justifyContent="center">
                <Flex justifyContent="center" position="relative" width="100%">
                  {unit === KeyTermUnit.USD && (
                    <CurrencyInput
                      ref={inputRef}
                      fontSize="24px"
                      fontWeight="medium"
                      placeholder="$0"
                      value={value ?? undefined}
                      onValueChange={(value) => {
                        const newParsedValue = value
                          ? Number.parseFloat(value)
                          : null;
                        onUpdateValue(newParsedValue);
                      }}
                    />
                  )}
                  {unit === KeyTermUnit.DAYS && (
                    <NumberInput
                      size="lg"
                      value={value ?? ''}
                      width="100%"
                      onChange={(_, value) => {
                        if (isNaN(value)) {
                          onUpdateValue(null);
                        } else {
                          onUpdateValue(value);
                        }
                      }}
                    >
                      <NumberInputField
                        ref={inputRef}
                        backgroundColor="input.bg"
                        fontSize="24px"
                        fontWeight="medium"
                        placeholder="0 days"
                        value={value ?? ''}
                      />
                    </NumberInput>
                  )}
                  {!isSingleValue && range && unit && !isNil(value) && (
                    <Flex
                      backgroundColor={dotColor}
                      borderRadius="full"
                      boxSize={2}
                      position="absolute"
                      right={3}
                      top={5}
                    />
                  )}
                </Flex>
                <Flex paddingTop={2}>
                  <NotesPopover
                    label={label}
                    noteUser={buyersAgentUser}
                    notes={notes}
                    onChange={(notes) => onUpdateNotes(notes)}
                  />
                </Flex>
              </Flex>
            </Flex>
            {isExpanded && !isSingleValue && range && unit && (
              <KeyTermsRangeChart
                dotColor={dotColor}
                range={range}
                source={source}
                unit={unit}
                value={value}
              />
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
