/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */
import * as duckdb from '@duckdb/duckdb-wasm';

let db: duckdb.AsyncDuckDB;

export interface TableSchema {
  name: string;
  type: string;
}

export async function getDuckDB() {
  if (db) {
    return db;
  }

  const JSDELIVR_BUNDLES = duckdb.getJsDelivrBundles();

  // Select a bundle based on browser checks
  const bundle = await duckdb.selectBundle(JSDELIVR_BUNDLES);

  const worker_url = URL.createObjectURL(
    new Blob([`importScripts("${bundle.mainWorker!}");`], {
      type: 'text/javascript',
    })
  );

  // Instantiate the asynchronus version of DuckDB-Wasm
  const worker = new Worker(worker_url);
  const logger = new duckdb.VoidLogger();
  const tmpDb = new duckdb.AsyncDuckDB(logger, worker);
  await tmpDb.instantiate(bundle.mainModule, bundle.pthreadWorker);
  URL.revokeObjectURL(worker_url);

  db = tmpDb;

  return db;
}

export async function runQuery(query: string) {
  const db = await getDuckDB();
  const connection = await db.connect();

  const result = await connection.query(query);

  await connection.close();

  return result.toArray().map((row) => row.toJSON());
}

export async function insertJson(
  tableName: string,
  json: Record<string, any>[]
) {
  const db = await getDuckDB();
  const connection = await db.connect();
  const fileName = `${tableName}-${Date.now()}.json`;

  await db.registerFileText(fileName, JSON.stringify(json));
  await connection.query(`DROP TABLE IF EXISTS ${tableName}`);
  await connection.insertJSONFromPath(fileName, {
    name: tableName,
    create: true,
  });

  await connection.close();

  return tableName;
}

export async function getTableSchema(
  tableName: string
): Promise<TableSchema[]> {
  const db = await getDuckDB();
  const connection = await db.connect();

  const result = await connection.query(`
    SELECT column_name, data_type
    FROM information_schema.columns
    WHERE table_name = '${tableName}'
  `);

  await connection.close();

  return result.toArray().map((row) => ({
    name: row.column_name,
    type: row.data_type,
  }));
}
