import { Portal, VStack } from '@chakra-ui/react';
import { MlsListingFragment } from '@client/graphql/__generated__/types';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  useSensor,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { useBuyerOfferAnalysisContext } from '~/apps/consumer/components/BuyerOfferAnalysis/Context/BuyerOfferAnalysisContext';
import { useDndKitDefaults } from '~/common/hooks/useDndKitDefaults';
import { EditCompsMapSidebarListingCard } from './EditCompsMapSidebarListingCard';
export const DraggableEditCompsMapSidebarListingCards = ({
  containerRef,
}: {
  containerRef: React.RefObject<HTMLDivElement>;
}) => {
  const { comps, setComps } = useBuyerOfferAnalysisContext();
  const {
    sensors,
    dropAnimationConfig,
    activeDraggingItemData,
    setActiveDraggingItemData,
  } = useDndKitDefaults<MlsListingFragment>({
    extendSensors: [
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      }),
    ],
  });

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const activeComparable = comps?.find(
      (comp) => comp.mlsListing.id === active.id
    );
    setActiveDraggingItemData(
      activeComparable?.mlsListing as MlsListingFragment
    );
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over?.id && active.id !== over.id) {
      const oldIndex = comps.findIndex(
        (comp) => comp.mlsListing.id === active.id
      );
      const newIndex = comps.findIndex(
        (comp) => comp.mlsListing.id === over.id
      );

      const newComps = arrayMove(comps, oldIndex, newIndex);

      setComps(newComps || []);
    }
    setActiveDraggingItemData(null);
  };

  const compsList = (
    <VStack
      gap={2}
      height="100%"
      minHeight="0"
      overflowY="auto"
      p={2}
      width="100%"
    >
      {comps?.map((comp) => {
        const onDelete = (mlsListing: MlsListingFragment) => {
          const index = comps.findIndex(
            (comp) => comp.mlsListing.id === mlsListing.id
          );
          setComps &&
            setComps((prevState) => {
              return prevState.toSpliced(index, 1);
            });
        };

        return (
          <SortableEditCompsMapSidebarListingCard
            key={comp.mlsListing.id}
            mlsListing={comp.mlsListing}
            onDelete={onDelete}
          />
        );
      })}
    </VStack>
  );

  return (
    <DndContext
      collisionDetection={closestCenter}
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext
        items={comps?.map((comp) => comp.mlsListing.id) || []}
        strategy={verticalListSortingStrategy}
      >
        {compsList}
      </SortableContext>
      <Portal containerRef={containerRef}>
        <DragOverlay dropAnimation={dropAnimationConfig}>
          {activeDraggingItemData ? (
            <EditCompsMapSidebarListingCard
              mlsListing={activeDraggingItemData}
            />
          ) : null}
        </DragOverlay>
      </Portal>
    </DndContext>
  );
};

export const SortableEditCompsMapSidebarListingCard = ({
  mlsListing,
  onDelete,
}: {
  mlsListing: MlsListingFragment;
  onDelete?: (mlsListing: MlsListingFragment) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: mlsListing.id });

  return (
    <EditCompsMapSidebarListingCard
      ref={setNodeRef}
      attributes={attributes}
      listeners={listeners}
      mlsListing={mlsListing}
      transform={transform}
      transition={transition}
      onDelete={onDelete}
    />
  );
};
