import {
  OrgTransactionStatsByZipCodeObject,
  PerAgentOfferAcceptRateFragment,
  PerAgentTransactionStatsFragment,
} from '@client/graphql/__generated__/types';
import { useCopilotAction } from '@copilotkit/react-core';
import { useEffect, useState } from 'react';
import {
  getTableSchema,
  insertJson,
  runQuery,
  TableSchema,
} from '~/common/utils/duckdb';

interface OrgPerformanceMetricsActionOptions {
  orgTransactionStats: OrgTransactionStatsByZipCodeObject[];
  agentPerformanceData: (Omit<PerAgentOfferAcceptRateFragment, '__typename'> &
    Omit<PerAgentTransactionStatsFragment, '__typename'>)[];
}

const agentPerformanceMetricsDescription = (tableSchema: TableSchema[]) => `
Provide agent performance metrics data based on the sql query provided.
The table schema is as follows:

TABLE agent_performance_data (
  ${tableSchema.map((schema) => `${schema.name} ${schema.type}`).join(',\n')}
)
`;

const orgTransactionStatsDescription = (tableSchema: TableSchema[]) => `
Provide org transaction stats data based on the sql query provided. Always use total_volume and total_homes_count.
The table schema is as follows:

TABLE org_transaction_stats (
  ${tableSchema.map((schema) => `${schema.name} ${schema.type}`).join(',\n')}
)
`;

export const useOrgPerformanceMetricsAction = (
  options: OrgPerformanceMetricsActionOptions
) => {
  const [agentPerformanceDataSchema, setAgentPerformanceDataSchema] = useState<
    TableSchema[]
  >([]);
  const [orgTransactionStatsSchema, setOrgTransactionStatsSchema] = useState<
    TableSchema[]
  >([]);

  useEffect(() => {
    const insertAndGetSchema = async () => {
      if (options.agentPerformanceData.length > 0) {
        await insertJson(
          'agent_performance_data',
          options.agentPerformanceData
        );

        const agentPerformanceDataSchema = await getTableSchema(
          'agent_performance_data'
        );

        setAgentPerformanceDataSchema(agentPerformanceDataSchema);
      }

      if (options.orgTransactionStats.length > 0) {
        await insertJson('org_transaction_stats', options.orgTransactionStats);

        const orgTransactionStatsSchema = await getTableSchema(
          'org_transaction_stats'
        );

        setOrgTransactionStatsSchema(orgTransactionStatsSchema);
      }
    };

    void insertAndGetSchema();
  }, [options.agentPerformanceData, options.orgTransactionStats]);

  useCopilotAction({
    name: 'agentPerformanceMetrics',
    description: agentPerformanceMetricsDescription(agentPerformanceDataSchema),
    parameters: [
      {
        name: 'sqlQuery',
        type: 'string',
        description: 'DuckDB SQL query to get org performance metrics data',
        required: true,
      },
    ],
    handler: async (args) => {
      const result = await runQuery(args.sqlQuery);

      return `Format all volume metrics as USD, list the data in a table. \`\`\`json\n${JSON.stringify(
        result
      )}\`\`\``;
    },
  });

  useCopilotAction({
    name: 'orgTransactionStats',
    description: orgTransactionStatsDescription(orgTransactionStatsSchema),
    parameters: [
      {
        name: 'sqlQuery',
        type: 'string',
        description: 'DuckDB SQL query to get org transaction stats data',
        required: true,
      },
    ],
    handler: async (args) => {
      const result = await runQuery(args.sqlQuery);

      return `Format all volume metrics as USD, list the data in a table. \`\`\`json\n${JSON.stringify(
        result
      )}\`\`\``;
    },
  });
};
