import { Container, Flex, VStack } from '@chakra-ui/react';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useNavigate } from 'react-router-dom';
import { AgentNotesCard } from '../../components/BuyerOfferAnalysis/AgentNotesCard';
import { BuyerOfferAnalysisHeader } from '../../components/BuyerOfferAnalysis/BuyerOfferAnalysisHeader';
import { BuyerOfferAnalysisTopTags } from '../../components/BuyerOfferAnalysis/BuyerOfferAnalysisTopTags';
import { CompsCard } from '../../components/BuyerOfferAnalysis/Comps/CompsCard';
import { EditCompsModal } from '../../components/BuyerOfferAnalysis/Comps/EditCompsModal/EditCompsModal';
import { BuyerOfferAnalysisProvider } from '../../components/BuyerOfferAnalysis/Context/BuyerOfferAnalysisContext';
import { ContingenciesTable } from '../../components/BuyerOfferAnalysis/KeyTermsAndContingencies/ContingenciesTable';
import { KeyTermsTable } from '../../components/BuyerOfferAnalysis/KeyTermsAndContingencies/KeyTermsTable';
import { BuyerOfferAnalysisLeftSidebar } from '../../components/BuyerOfferAnalysis/LeftSidebar/BuyerOfferAnalysisLeftSidebar';
import { OfferScoreCard } from '../../components/BuyerOfferAnalysis/OfferScore/OfferScoreCard';
import { PurchasePriceCard } from '../../components/BuyerOfferAnalysis/PurchasePrice/PurchasePriceCard';
import { CONTAINER_PADDINGS } from '../../components/Layouts/Container';

export const BuyerOfferAnalysisPage = () => {
  const isBuyerOfferAnalysisEnabled = useFeatureFlagEnabled('offer-coach');
  const navigate = useNavigate();

  if (!isBuyerOfferAnalysisEnabled) {
    navigate('/');
  }

  return (
    <BuyerOfferAnalysisProvider>
      <BuyerOfferAnalysisHeader />
      <Flex height="100%" justifyContent="space-between">
        <BuyerOfferAnalysisLeftSidebar />
        <Container
          centerContent
          maxW="100%"
          minW="container.md"
          {...CONTAINER_PADDINGS}
        >
          <VStack gap={4} width="100%">
            <BuyerOfferAnalysisTopTags />
            <AgentNotesCard />
            <Flex gap={4} width="100%">
              <CompsCard />
              <PurchasePriceCard />
              <OfferScoreCard />
            </Flex>
            <KeyTermsTable />
            <ContingenciesTable />
          </VStack>
          <EditCompsModal />
        </Container>
      </Flex>
    </BuyerOfferAnalysisProvider>
  );
};
