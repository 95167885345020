import { Button, Divider, Flex, Text } from '@chakra-ui/react';
import {
  ListingComparableSetDocument,
  ListingComparableSetForBuyerOfferAnalysisDocument,
  useCreateOrUpdateListingComparableAdjustmentMutation,
} from '@client/graphql/__generated__/main-operations';
import {
  ListingComparableSetFragment,
  MlsListingFragment,
} from '@client/graphql/__generated__/types';
import { useEffect, useRef, useState } from 'react';
import { CurrencyInput } from '~/common/components/Inputs/CurrencyInput';
import { formatToCurrency } from '~/services/document/utils/number';
import { BaseComparableField } from '../../../../Comparables/BaseComparableField';
import { ADJUSTABLE_PRICE_FIELD_HEIGHT } from '../constants';

interface AdjustablePriceFieldProps {
  comparableSet: ListingComparableSetFragment;
  mlsListing: MlsListingFragment;
  onUpdate?: () => Promise<void> | void;
}

export const AdjustablePriceField = ({
  comparableSet,
  mlsListing,
  onUpdate,
}: AdjustablePriceFieldProps) => {
  const [updateListingComparableAdjustment] =
    useCreateOrUpdateListingComparableAdjustmentMutation();
  const [adjustedPrice, setAdjustedPrice] = useState<number | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const compSetAdjustedPrice = comparableSet?.listingComparables?.find(
    (comparable) => comparable.mlsListing.id === mlsListing.id
  )?.adjustments?.priceAdjustment;

  const updateAdjustedPrice = (value: number | null) => {
    if (value !== compSetAdjustedPrice) {
      void (async () => {
        await updateListingComparableAdjustment({
          variables: {
            input: {
              listingComparableSetId: comparableSet?.id,
              mlsListingId: mlsListing?.id,
              adjustments: {
                priceAdjustment: value ?? (null as unknown as number),
              },
            },
          },
          refetchQueries: [
            // Add any other queries that need to be refetched here.
            // Its ok to have queries that are not related to the current page.
            ListingComparableSetForBuyerOfferAnalysisDocument,
            ListingComparableSetDocument,
          ],
        });

        await onUpdate?.();
      })();
    }
  };

  useEffect(() => {
    const newAdjustedPrice = comparableSet?.listingComparables?.find(
      (comparable) => comparable.mlsListing.id === mlsListing.id
    )?.adjustments?.priceAdjustment;
    setAdjustedPrice(newAdjustedPrice ?? null);
  }, [comparableSet?.listingComparables, mlsListing.id]);

  const priceTitle = mlsListing?.sales?.closePrice
    ? 'Sold price'
    : 'List price';

  const priceValue = mlsListing?.sales?.closePrice || mlsListing?.listPrice;

  const priceText = formatToCurrency(priceValue);

  const adjustmentText = adjustedPrice
    ? formatToCurrency(adjustedPrice)
    : 'Add';

  const adjustedPriceTitle = adjustedPrice ? 'Adjusted price' : priceTitle;

  const adjustedPriceText = adjustedPrice
    ? formatToCurrency(priceValue ? priceValue + adjustedPrice : 0)
    : priceText;

  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  return (
    <>
      <BaseComparableField height={ADJUSTABLE_PRICE_FIELD_HEIGHT}>
        <Flex direction="column" px={4} py={2} width="100%">
          <Flex alignItems="center" justifyContent="space-between">
            <Text color="whiteAlpha.700">{priceTitle}</Text>
            <Text color="whiteAlpha.700">{priceText}</Text>
          </Flex>
          <Flex alignItems="center" gap={16} justifyContent="space-between">
            <Text color="whiteAlpha.700">Adjustment</Text>
            {isEditing ? (
              <CurrencyInput
                ref={inputRef}
                borderRadius="0"
                height="24px"
                paddingRight={0}
                textAlign="right"
                value={adjustedPrice ?? undefined}
                width="100%"
                onBlur={() => {
                  updateAdjustedPrice(adjustedPrice);
                  setIsEditing(false);
                }}
                onKeyDown={(e) => {
                  //prevent dnd keyboard sensor from triggering
                  e.stopPropagation();

                  if (e.key === 'Enter') {
                    (e.target as HTMLInputElement).blur();
                  }
                }}
                onMouseDown={(e) => {
                  // prevent drag and drop from triggering and unfocusing input
                  e.stopPropagation();
                }}
                onValueChange={(value: string | undefined) => {
                  const parsedValue = value ? parseFloat(value) : null;
                  setAdjustedPrice(parsedValue);
                }}
              />
            ) : (
              <Button
                _hover={{
                  color: 'cyan.700',
                }}
                color="cyan.500"
                minWidth="0"
                p={0}
                textDecoration="underline"
                variant="link"
                onClick={() => {
                  setIsEditing(true);
                }}
                onMouseDown={(e) => {
                  // prevent drag and drop from triggering and unfocusing input
                  e.stopPropagation();
                }}
              >
                {adjustmentText}
              </Button>
            )}
          </Flex>
          <Divider py={1} />

          <Flex alignItems="center" justifyContent="space-between">
            <Text color="whiteAlpha.700">{adjustedPriceTitle}</Text>
            <Text fontSize="lg" fontWeight="medium">
              {adjustedPriceText}
            </Text>
          </Flex>
        </Flex>
      </BaseComparableField>
    </>
  );
};
