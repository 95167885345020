import { Button, Flex, Heading, Icon, Tag, Text } from '@chakra-ui/react';
import { CompetitivenessLevel } from '@client/graphql/__generated__/types';
import { DotIcon, MessageSquarePlusIcon } from 'lucide-react';
import { formatPercent } from '~/common/utils/formatter';
import { useBuyerOfferAnalysisContext } from './Context/BuyerOfferAnalysisContext';

const COMPETITION_COLORS: Record<CompetitivenessLevel, string> = {
  [CompetitivenessLevel.VERY_COMPETITIVE]: 'red.500',
  [CompetitivenessLevel.COMPETITIVE]: 'yellow.500',
  [CompetitivenessLevel.NOT_COMPETITIVE]: 'green.500',
  [CompetitivenessLevel.NOT_COMPETITIVE_AT_ALL]: 'green.500',
  [CompetitivenessLevel.UNKNOWN]: 'gray.500',
};

export const BuyerOfferAnalysisTopTags = () => {
  const { offerRecs, setIsEditingAgentNotes, isEditingAgentNotes, agentNotes } =
    useBuyerOfferAnalysisContext();

  const competitionLevel = offerRecs?.competitiveness?.rating;

  const percentCloseVsAsk = offerRecs?.competitiveness.metrics.bidAsk
    ? offerRecs.competitiveness.metrics.bidAsk - 1
    : null;

  const daysOnMarket =
    offerRecs?.competitiveness.metrics.daysOnMarket.toFixed(0);

  if (competitionLevel === CompetitivenessLevel.UNKNOWN) {
    return null;
  }

  return (
    <Flex alignItems="center" justifyContent="space-between" width="100%">
      <Flex
        alignItems="center"
        gap={2}
        justifyContent="flex-start"
        width="100%"
      >
        <Heading
          color="whiteAlpha.600"
          fontSize="sm"
          fontWeight="medium"
          size="smallUppercase"
        >
          Situation
        </Heading>
        {competitionLevel && (
          <Tag gap={1}>
            <Icon
              as={DotIcon}
              color={COMPETITION_COLORS[competitionLevel]}
              strokeWidth={8}
            />
            <Text fontWeight="medium" textTransform="capitalize">
              {competitionLevel.toLowerCase()?.split('_')?.join(' ')}
            </Text>
          </Tag>
        )}
        {percentCloseVsAsk && (
          <Tag gap={1}>
            <Text fontWeight="medium">
              {`${percentCloseVsAsk > 0 ? '+' : ''}${formatPercent(
                percentCloseVsAsk
              )}`}{' '}
              average close vs ask
            </Text>
          </Tag>
        )}
        {daysOnMarket && (
          <Tag gap={1}>
            <Text fontWeight="medium">
              {daysOnMarket} average days on market
            </Text>
          </Tag>
        )}
      </Flex>
      {!isEditingAgentNotes && !agentNotes && (
        <Button
          borderRadius="48px"
          leftIcon={<Icon as={MessageSquarePlusIcon} color="cyan.500" />}
          px={4}
          size="xs"
          variant="outline"
          onClick={() => setIsEditingAgentNotes(true)}
        >
          <Text color="white" fontWeight="medium">
            Add note to buyer
          </Text>
        </Button>
      )}
    </Flex>
  );
};
