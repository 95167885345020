import {
  Avatar,
  AvatarGroup,
  Box,
  Card,
  CardBody,
  Heading,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Skeleton,
  Stack,
  Tag,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FC, memo, useMemo } from 'react';
import { classify } from '~/services/document/utils/string';

// Types
interface Agent {
  agent_name?: string;
  value?: string | number;
}

interface TopPerformer {
  title?: string;
  agents?: Agent[];
}

interface TopPerformersProps {
  topPerformers: TopPerformer[];
}

// Utility functions
const getAgentDisplayInfo = (agents: Agent[] | undefined) => {
  if (!agents?.length) return null;

  const agentsLength = agents.length;
  const value = agents[0].value;
  let agentNames = '';

  if (agentsLength === 1) {
    agentNames = agents[0].agent_name!;
  } else if (agentsLength === 2) {
    agentNames = agents.map((agent) => agent.agent_name).join(' and ');
  } else if (agentsLength > 2) {
    agentNames = `${agents[0].agent_name} and ${agentsLength - 1} others`;
  }

  return { agentNames, value };
};

// Sub-components
const AgentAvatar: FC<{ agents: Agent[] | undefined }> = memo(
  function AgentAvatar({ agents }) {
    const agentsLength = agents?.length || 0;

    if (!agents?.length) return null;

    if (agentsLength === 2) {
      return (
        <AvatarGroup max={2} size="lg">
          {agents.map((agent) => (
            <Avatar key={agent.agent_name} name={agent.agent_name} />
          ))}
        </AvatarGroup>
      );
    }

    if (agentsLength > 2) {
      return (
        <Box position="relative">
          <Avatar name={agents[0].agent_name} size="lg" />
          <Popover placement="bottom-end" trigger="hover">
            <PopoverTrigger>
              <Tag bgColor="gray.600" bottom={0} position="absolute" right={-4}>
                +{agentsLength - 1}
              </Tag>
            </PopoverTrigger>
            <Portal>
              <PopoverContent width="auto" zIndex={10}>
                <PopoverArrow />
                <PopoverBody alignItems="stretch" as={VStack} spacing={2}>
                  {agents.map((agent) => (
                    <HStack key={agent.agent_name} spacing={1}>
                      <Avatar
                        mr={2}
                        name={classify(agent.agent_name || '')}
                        size="xs"
                      />
                      <Text>{classify(agent.agent_name || '')}</Text>
                    </HStack>
                  ))}
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Box>
      );
    }

    return <Avatar name={agents[0].agent_name} size="lg" />;
  }
);

const AgentInfo: FC<{ agents: Agent[] | undefined }> = memo(function AgentInfo({
  agents,
}) {
  const agentInfo = useMemo(() => getAgentDisplayInfo(agents), [agents]);

  if (!agentInfo) return null;

  return (
    <VStack
      alignItems="center"
      justifyContent="center"
      spacing={0}
      textAlign="center"
      width="100%"
    >
      <Text
        color="white"
        flexShrink={1}
        fontSize="sm"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        width="100%"
      >
        {agentInfo.agentNames}
      </Text>
      <Text color="whiteAlpha.600">{agentInfo.value}</Text>
    </VStack>
  );
});

const TopPerformerAgents: FC<{ agents: Agent[] | undefined }> = memo(
  function TopPerformerAgents({ agents }) {
    if (!agents?.length) {
      return (
        <VStack
          alignItems="center"
          flexGrow={0}
          height="100%"
          justifyContent="center"
          width="100%"
        >
          <Text color="whiteAlpha.600">Not enough data</Text>
          <Text color="whiteAlpha.600">Try changing or removing filters</Text>
        </VStack>
      );
    }

    return (
      <VStack
        alignItems="center"
        flexGrow={0}
        justifyContent="center"
        width="100%"
      >
        <AgentAvatar agents={agents} />
        <AgentInfo agents={agents} />
      </VStack>
    );
  }
);

export const TopPerformers: FC<TopPerformersProps> = memo(
  function TopPerformers({ topPerformers }) {
    const hasAgents = useMemo(
      () => topPerformers.some((topPerformer) => topPerformer.agents?.length),
      [topPerformers]
    );

    return (
      <VStack
        alignItems="stretch"
        flexGrow={1}
        justifyContent="stretch"
        spacing={4}
      >
        <Heading as="h2" fontSize="xl">
          Top Performers
        </Heading>

        <Stack
          alignItems="stretch"
          flexDir={{
            base: 'column',
            lg: 'row',
          }}
          flexGrow={1}
          justifyContent="space-evenly"
          spacing={3}
        >
          {!hasAgents && (
            <>
              <Skeleton height="188px" width="100%" />
              <Skeleton height="188px" width="100%" />
              <Skeleton height="188px" width="100%" />
            </>
          )}
          {hasAgents &&
            topPerformers.map((topPerformer) => (
              <Card key={topPerformer.title} width="100%">
                <CardBody as={VStack} px={3} spacing={3} textAlign="center">
                  <Heading
                    color="whiteAlpha.600"
                    fontWeight="500"
                    size="smallUppercase"
                  >
                    {topPerformer.title}
                  </Heading>
                  <TopPerformerAgents agents={topPerformer.agents} />
                </CardBody>
              </Card>
            ))}
        </Stack>
      </VStack>
    );
  }
);
