import { useCallback, useState } from 'react';
import { MapMouseEvent } from 'react-map-gl';

export const useZipCodesGeoInteraction = () => {
  const [selectedZipCodes, setSelectedZipCodes] = useState<string[]>([]);

  const handleMapClick = useCallback((event: MapMouseEvent) => {
    const map = event.target;
    const features = map.queryRenderedFeatures(event.point, {
      layers: ['multi-polygon'],
    });

    if (features.length > 0) {
      const shiftKey = event.originalEvent.shiftKey;
      const selectedZipCode = features[0].properties?.zipCode as string;

      setSelectedZipCodes((prev) => {
        if (shiftKey) {
          if (prev.includes(selectedZipCode)) {
            return prev.filter((zipCode) => zipCode !== selectedZipCode);
          }

          return [...prev, selectedZipCode];
        }

        return [selectedZipCode];
      });
    } else {
      setSelectedZipCodes([]);
    }
  }, []);

  return {
    selectedZipCodes,
    setSelectedZipCodes,
    handleMapClick,
  };
};
