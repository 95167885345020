import { FormLabel, VStack, useDisclosure } from '@chakra-ui/react';
import { useEffect, useMemo, useRef, useState } from 'react';

import debounce from 'lodash/debounce';
import { useOfferFlowContext } from '~/apps/consumer/pages/Storefront/OfferFlow/OfferFlowContext';
import { CollapsibleCard } from '~/common/components/CollapsibleCard/CollapsibleCard';
import { ConfirmationPopover } from '~/common/components/ConfirmationPopover/ConfirmationPopover';
import { ToggleButtons } from '~/common/components/ToggleButtons/ToggleButtons';
import { useLocalStorageState } from '~/common/hooks/useLocalStorageState';
import { AddFillableTab } from './AddFillableTab';
import { UploadDocsTab } from './UploadDocsTab';

enum TabEnum {
  FILLABLE = 'fillable',
  DISCLOSURES = 'disclosures',
}

export const AddAddendaCard = () => {
  const {
    latestOffer,
    agentNegotiationContractDocs,
    disclosureContractDocs,
    otherDisclosureContractDocs,
  } = useOfferFlowContext();
  const [showTooltip, setShowTooltip] = useState(false);
  const { localStorageState, updateLocalStorageState, saveLocalStorageState } =
    useLocalStorageState<{ hasSeenTooltip: boolean }>(
      `add-docs-tooltip-${latestOffer?.id ?? ''}`
    );
  const {
    isOpen: isExpanded,
    onOpen: onExpand,
    onClose: onCollapse,
  } = useDisclosure({ defaultIsOpen: true });
  const [activeTab, setActiveTab] = useState<TabEnum>(TabEnum.FILLABLE);

  const tabs = [
    { label: 'Add fillable addenda', value: TabEnum.FILLABLE },
    { label: 'Upload', value: TabEnum.DISCLOSURES },
  ];

  const debouncedSetShowTooltip = useMemo(
    () =>
      debounce((showTooltip: boolean) => {
        setShowTooltip(showTooltip);
      }, 100),
    [setShowTooltip]
  );

  const allKnownDisclosureContractDocs = [
    ...agentNegotiationContractDocs,
    ...disclosureContractDocs,
    ...otherDisclosureContractDocs,
  ];

  const hasUploadedDisclosures = !!allKnownDisclosureContractDocs?.length;

  useEffect(() => {
    if (latestOffer?.id) {
      debouncedSetShowTooltip(!localStorageState.hasSeenTooltip);
    }
  }, [
    localStorageState.hasSeenTooltip,
    latestOffer?.id,
    debouncedSetShowTooltip,
  ]);

  const saveLocalStorageRef = useRef(saveLocalStorageState);
  useEffect(() => {
    saveLocalStorageRef.current = saveLocalStorageState;
  }, [saveLocalStorageState]);

  return (
    <CollapsibleCard
      body={
        <>
          <ToggleButtons<TabEnum>
            activeButton={activeTab}
            buttons={tabs}
            setActiveButton={setActiveTab}
          />
          <VStack height="100%">
            {activeTab === TabEnum.FILLABLE ? (
              <AddFillableTab />
            ) : (
              <UploadDocsTab />
            )}
          </VStack>
        </>
      }
      header={
        <ConfirmationPopover
          anchor={<FormLabel>Add Additional Documents</FormLabel>}
          handleClick={() => {
            setShowTooltip(false);
            updateLocalStorageState({ hasSeenTooltip: true });
            setTimeout(() => {
              saveLocalStorageRef.current();
            }, 1);
          }}
          isOpen={showTooltip}
          text={`${
            hasUploadedDisclosures
              ? `We’ve already added the Listing Agent’s disclosures. `
              : ''
          }Please add any documents you’d like to include in the offer package.`}
        />
      }
      isExpanded={isExpanded}
      onCollapse={onCollapse}
      onExpand={onExpand}
    />
  );
};
