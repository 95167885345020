/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { withLazyComponent } from '@document/utils/lazy-component';
import { lazy } from 'react';

export const CopilotChat = withLazyComponent(
  lazy(() => import('./CopilotChat'))
);

export const CopilotKit = withLazyComponent(lazy(() => import('./CopilotKit')));
