import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Flex,
  Icon,
  Image,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { BathIcon, BedSingleIcon } from 'lucide-react';
import {
  getListingPrice,
  getPropertySource,
} from '~/apps/consumer/utils/listing.utils';
import { formatToCurrency } from '~/services/document/utils/number';
import { useBuyerOfferAnalysisContext } from '../Context/BuyerOfferAnalysisContext';
import { CompsMap } from './CompsMap';
import { CompsSummary } from './CompsSummary';

export const CompsCard = () => {
  const {
    comps,
    comparableSet,
    listing,
    comparableSetLoading,
    onEditCompsModalOpen,
  } = useBuyerOfferAnalysisContext();

  if (!listing || comparableSetLoading) {
    return <Skeleton flexBasis={0} flexGrow={3} height="250px" />;
  }

  return (
    <Card flexBasis={0} flexGrow={3} height="250px">
      <CardHeader>
        <Flex alignItems="center" gap={2} justifyContent="space-between">
          <Text color="whiteAlpha.600">Comps</Text>
          <Button
            size="xs"
            variant="rounded-outline"
            onClick={() => onEditCompsModalOpen()}
          >
            Edit Comps
          </Button>
        </Flex>
      </CardHeader>
      <CardBody pr={0}>
        <Flex alignItems="center" gap={8} height="100%">
          <Flex direction="column" gap={2} minWidth="120px" width="120px">
            <CompsSummary compact />
          </Flex>
          <Flex
            height="140px"
            minWidth="220px"
            width="220px"
            onClick={() => onEditCompsModalOpen()}
          >
            <CompsMap
              isMini
              comparableSet={comparableSet}
              mainListing={listing}
            />
          </Flex>
          <Divider orientation="vertical" />
          <Flex alignItems="center" gap={4} height="100%" overflow="auto">
            {comps?.map((comp) => (
              <Flex
                key={comp.mlsListing?.id}
                _after={{
                  content: '""',
                  position: 'absolute',
                  background:
                    'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.6) 100%)',
                  zIndex: 1,
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}
                cursor="pointer"
                direction="column"
                minHeight="140px"
                minWidth="220px"
                position="relative"
                onClick={() => onEditCompsModalOpen(comp)}
              >
                <Image
                  key={comp.mlsListing?.id}
                  borderRadius="6px"
                  height="140px"
                  src={getPropertySource(comp.mlsListing?.photos?.[0])}
                  width="220px"
                />
                <Flex
                  bottom="0"
                  direction="column"
                  overflow="hidden"
                  p={2}
                  position="absolute"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  zIndex={2}
                >
                  <Text>{comp.mlsListing?.address?.full}</Text>
                  <Flex alignItems="center" gap={2}>
                    <Text>
                      {formatToCurrency(getListingPrice(comp.mlsListing))}
                    </Text>
                    <Flex alignItems="center" gap={1}>
                      <Text>{comp.mlsListing?.property?.bedrooms}</Text>
                      <Icon as={BedSingleIcon} />
                    </Flex>
                    <Flex alignItems="center" gap={1}>
                      <Text>{comp.mlsListing?.property?.bathrooms}</Text>
                      <Icon as={BathIcon} />
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </CardBody>
    </Card>
  );
};
