import { Flex, IconButton } from '@chakra-ui/react';
import {
  ListingComparableSetFragment,
  MlsListingFragment,
} from '@client/graphql/__generated__/types';
import { DraggableAttributes } from '@dnd-kit/core';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';
import { CSS, Transform } from '@dnd-kit/utilities';
import get from 'lodash/get';
import isNil from 'lodash/isNil';
import { Trash2 } from 'lucide-react';
import { forwardRef, memo, useState } from 'react';
import {
  getFormattedStatusText,
  getPropertySource,
} from '~/apps/consumer/utils/listing.utils';
import { COMPARABLE_FIELDS } from '../constants';
import { ComparableField } from './ComparableField';
import { ComparablePhoto } from './ComparablePhoto';
import { AdjustablePriceField } from './CustomFields/AdjustablePriceField';
import { ListingAgentComparableField } from './ListingAgentComparableField';
import { ADJUSTABLE_PRICE_FIELD_HEIGHT, COLUMN_WIDTH } from './constants';

export interface ComparablesColumnProps {
  showAdjustments?: boolean;
  isEditMode?: boolean;
  mainMlsListing?: MlsListingFragment;
  mlsListing: MlsListingFragment;
  isMainListing?: boolean;
  onDelete?: (mlsListing: MlsListingFragment) => void;
  transition?: string;
  transform?: Transform | null;
  attributes?: DraggableAttributes;
  listeners?: SyntheticListenerMap;
  comparableSet?: ListingComparableSetFragment;
  showNotes?: boolean;
  onAdjustmentsUpdate?: () => Promise<void> | void;
}

export const ComparableColumn = memo(
  forwardRef<HTMLDivElement, ComparablesColumnProps>(
    (
      {
        mlsListing,
        isMainListing,
        isEditMode,
        mainMlsListing,
        onDelete,
        transition,
        transform,
        attributes = {} as DraggableAttributes,
        listeners = {},
        comparableSet,
        showAdjustments = false,
        showNotes = false,
        onAdjustmentsUpdate,
      }: ComparablesColumnProps,
      ref
    ) => {
      const [isHovered, setIsHovered] = useState(false);

      return (
        <Flex
          ref={ref}
          alignSelf={isMainListing ? 'flex-start' : undefined}
          backgroundColor={isMainListing ? 'bg.emphasis' : 'transparent'}
          direction="column"
          flexShrink={0}
          left={0}
          maxWidth={COLUMN_WIDTH}
          position={isMainListing ? 'sticky' : 'relative'}
          sx={{
            touchAction: 'manipulate',
          }}
          transform={CSS.Transform.toString(transform as Transform)}
          transition={transition}
          zIndex={isMainListing ? 1 : 0}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          {...attributes}
          {...listeners}
        >
          {!isMainListing && isEditMode && isHovered && (
            <IconButton
              isRound
              aria-label="delete"
              backgroundColor="black"
              boxSize="24px"
              color="whiteAlpha.800"
              icon={<Trash2 size={16} />}
              position="absolute"
              right={2}
              size="s"
              top={2}
              variant="solid"
              zIndex={1}
              onClick={() => {
                onDelete && onDelete(mlsListing);
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
            >
              Delete
            </IconButton>
          )}
          <ComparablePhoto
            closeDate={mlsListing?.sales?.closeDate}
            src={getPropertySource(mlsListing?.photos?.[0])}
            status={getFormattedStatusText(mlsListing?.mls?.statusText)}
          />
          {COMPARABLE_FIELDS.map((field) => {
            const isAdjustmentsRow =
              field.field === 'listPrice' && showAdjustments;

            if (isAdjustmentsRow && !isMainListing) {
              return (
                <AdjustablePriceField
                  comparableSet={comparableSet as ListingComparableSetFragment}
                  mlsListing={mlsListing}
                  onUpdate={onAdjustmentsUpdate}
                />
              );
            }

            const formatter = field.formatter;
            let value: string | number | undefined;

            if (field.field) {
              value = get(mlsListing, field.field) as
                | string
                | number
                | undefined;
            }

            if (field.valueComputer) {
              value = field.valueComputer(mlsListing);
            }

            const formattedValue =
              formatter && value
                ? formatter(value as number)
                : !isNil(value)
                  ? value
                  : 'N/A';

            let title = field.title;

            if (field.titleComputer) {
              title = field.titleComputer(mlsListing);
            }

            let subText: string | undefined;
            if (field.subValueComputer) {
              subText = field.subValueComputer(mlsListing, mainMlsListing);
            }

            const differenceValue = field?.differenceComputer?.(
              mlsListing,
              mainMlsListing as MlsListingFragment
            );

            const differenceDisplay =
              field?.differenceFormatter?.(differenceValue);
            const differenceStyle = field?.differenceStyler?.(differenceValue);

            const rightComponent =
              showNotes && !isMainListing
                ? field.rightComponent?.({
                    mlsListing,
                    comparableSet,
                    isEditMode,
                    isHovered,
                  })
                : null;

            return (
              <ComparableField
                key={field.field || title}
                differenceDisplay={differenceDisplay}
                differenceStyle={differenceStyle}
                height={
                  isAdjustmentsRow ? ADJUSTABLE_PRICE_FIELD_HEIGHT : undefined
                }
                rightComponent={rightComponent}
                showDifference={!isMainListing && !!differenceValue}
                showTitle={field.alwaysShowTitle || isMainListing}
                subText={subText as string}
                text={formattedValue as string}
                title={title as string}
              />
            );
          })}
          {isMainListing ? (
            <ComparableField
              key="listing-agent"
              showTitle={isMainListing}
              text={`${mlsListing.agent?.firstName || ''} ${
                mlsListing.agent?.lastName || ''
              }`.trim()}
              title="Listing Agent"
            />
          ) : (
            <ListingAgentComparableField
              company={mlsListing?.office?.name}
              email={mlsListing?.agent?.contact?.email}
              name={`${mlsListing.agent?.firstName || ''} ${
                mlsListing.agent?.lastName || ''
              }`.trim()}
              phoneNumber={mlsListing?.agent?.contact?.cell}
            />
          )}
        </Flex>
      );
    }
  )
);
