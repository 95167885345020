import { useEffect, useState } from 'react';
import { runQuery } from '~/common/utils/duckdb';

export const useDuckDBQuery = (sqlQuery: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const result = await runQuery(sqlQuery);
        setData(result);
      } catch (err) {
        setError(err as string);
      } finally {
        setLoading(false);
      }
    };

    void fetchData();
  }, [sqlQuery]);

  return { data, loading, error };
};
