import {
  useOrgTransactionStatsByZipCodeQuery,
  usePerAgentOfferAcceptRateQuery,
  usePerAgentTransactionStatsQuery,
  useZipCodesGeoQuery,
} from '@client/graphql/__generated__/main-operations';
import {
  OrgTransactionStatsByZipCodeQueryVariables,
  ZipCodesGeoQueryVariables,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import { useState } from 'react';

interface UseOrgPerformanceQueriesOptions {
  hasMapSuggestion: boolean;
  selectedZipCodes?: string[];
}

export const useOrgPerformanceQueries = ({
  hasMapSuggestion,
  selectedZipCodes,
}: UseOrgPerformanceQueriesOptions) => {
  const [zipCodesGeoFilters, setZipCodesGeoFilters] = useState<
    ZipCodesGeoQueryVariables['filters']
  >({
    zipCodes: [],
  });
  const [transactionFilters, setTransactionFilters] = useState<
    Omit<OrgTransactionStatsByZipCodeQueryVariables['filters'], 'zipCodes'>
  >({
    intervalDays: dayjs().diff(dayjs().startOf('year'), 'day'),
    priceMax: 0,
    priceMin: 0,
  });

  const { data: zipCodesGeoData, loading: zipCodesGeoLoading } =
    useZipCodesGeoQuery({
      variables: {
        filters: zipCodesGeoFilters,
      },
    });

  const zipCodesFilter = hasMapSuggestion
    ? zipCodesGeoData?.zipCodesGeo?.map((zipCode) => zipCode.zip_code) ?? []
    : [];

  const {
    data: orgTransactionStatsByZipCodeData,
    loading: orgTransactionStatsByZipCodeLoading,
  } = useOrgTransactionStatsByZipCodeQuery({
    variables: {
      filters: {
        ...transactionFilters,
        zipCodes: zipCodesFilter,
      },
    },
    onCompleted: (data) => {
      if (!hasMapSuggestion) {
        setZipCodesGeoFilters({
          zipCodes: Array.from(
            new Set(
              data?.orgTransactionStatsByZipCode?.map(
                (zipCode) => zipCode.zip_code
              ) ?? []
            )
          ),
        });
      }
    },
  });

  const {
    data: perAgentTransactionStatsData,
    loading: perAgentTransactionStatsLoading,
  } = usePerAgentTransactionStatsQuery({
    variables: {
      filters: {
        ...transactionFilters,
        zipCodes: zipCodesFilter,
      },
    },
  });

  const {
    data: perAgentOfferAcceptRateData,
    loading: perAgentOfferAcceptRateLoading,
  } = usePerAgentOfferAcceptRateQuery({
    variables: {
      filters: {
        ...transactionFilters,
        zipCodes: zipCodesFilter,
      },
    },
  });

  const {
    data: perAgentTransactionStatsDataBySelectedZipCodes,
    loading: perAgentTransactionStatsDataBySelectedZipCodesLoading,
  } = usePerAgentTransactionStatsQuery({
    variables: {
      filters: {
        ...transactionFilters,
        zipCodes: selectedZipCodes ?? [],
      },
    },
    skip: !selectedZipCodes?.length,
  });

  const {
    data: perAgentOfferAcceptRateDataBySelectedZipCodes,
    loading: perAgentOfferAcceptRateDataBySelectedZipCodesLoading,
  } = usePerAgentOfferAcceptRateQuery({
    variables: {
      filters: {
        ...transactionFilters,
        zipCodes: selectedZipCodes ?? [],
      },
    },
    skip: !selectedZipCodes?.length,
  });

  return {
    orgTransactionStatsByZipCodeData,
    perAgentTransactionStatsData,
    perAgentOfferAcceptRateData,
    zipCodesGeoData,
    transactionFilters,
    zipCodesGeoFilters,
    setZipCodesGeoFilters,
    setTransactionFilters,
    perAgentTransactionStatsDataBySelectedZipCodes,
    perAgentOfferAcceptRateDataBySelectedZipCodes,
    isLoading:
      orgTransactionStatsByZipCodeLoading ||
      perAgentTransactionStatsLoading ||
      perAgentOfferAcceptRateLoading ||
      zipCodesGeoLoading ||
      perAgentTransactionStatsDataBySelectedZipCodesLoading ||
      perAgentOfferAcceptRateDataBySelectedZipCodesLoading,
  };
};
