import { ApolloQueryResult } from '@apollo/client';
import { useGetBuyerOfferAnalysisQuery } from '@client/graphql/__generated__/main-operations';
import {
  BuyerOfferAnalysisFragment,
  Exact,
  ListingComparableSetForBuyerOfferAnalysisInput,
  ListingComparableSetForBuyerOfferAnalysisQuery,
  ListingComparableSetFragment,
  ListingFragment,
  OfferRecsFragment,
  OfferScoreResult,
} from '@client/graphql/__generated__/types';
import { createContext, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useOfferRecsActions } from '~/common/copilot/actions/useOfferRecsActions';
import { ComparableState } from '../../../pages/ListingSetup/Comparables/ComparablesPage';
import { useBuyerOfferAnalysisComps } from './hooks/useBuyerOfferAnalysisComps';
import { useUpdateBuyerOfferAnalysis } from './hooks/useUpdateBuyerOfferAnalysis';
import { AgentContingencyMap, AgentKeyTermMap } from './utils';

interface BuyerOfferAnalysisContextType {
  buyerOfferAnalysis?: BuyerOfferAnalysisFragment;
  listing?: ListingFragment;
  comparableSet?: ListingComparableSetFragment;
  comparableSetLoading: boolean;
  buyerOfferAnalysisLoading: boolean;
  offerRecsLoading: boolean;
  isEditCompsModalOpen: boolean;
  onEditCompsModalOpen: (comp?: ComparableState) => void;
  onEditCompsModalClose: () => void;
  comps: ComparableState[];
  setComps: React.Dispatch<React.SetStateAction<ComparableState[]>>;
  offerRecs?: OfferRecsFragment;
  refetchComparableSet: (
    variables?:
      | Partial<
          Exact<{ input: ListingComparableSetForBuyerOfferAnalysisInput }>
        >
      | undefined
  ) => Promise<
    ApolloQueryResult<ListingComparableSetForBuyerOfferAnalysisQuery>
  >;
  initialSelectedComp?: ComparableState;
  isCompsUpdated: boolean;
  setIsCompsUpdated: React.Dispatch<React.SetStateAction<boolean>>;
  isChangesSaved: boolean;
  title: string | undefined;
  setTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
  updateTitle: (newTitle?: string) => void;
  agentNotes: string | undefined;
  setAgentNotes: React.Dispatch<React.SetStateAction<string | undefined>>;
  updateAgentNotes: (newNotes?: string) => void;
  agentContingencies: AgentContingencyMap;
  setAgentContingencies: React.Dispatch<
    React.SetStateAction<AgentContingencyMap>
  >;
  agentKeyTerms: AgentKeyTermMap;
  setAgentKeyTerms: React.Dispatch<React.SetStateAction<AgentKeyTermMap>>;
  isEditingAgentNotes: boolean;
  setIsEditingAgentNotes: React.Dispatch<React.SetStateAction<boolean>>;
  offerScore?: OfferScoreResult;
  isCalculatingOfferScore: boolean;
}

export const BuyerOfferAnalysisContext =
  createContext<BuyerOfferAnalysisContextType>(
    {} as BuyerOfferAnalysisContextType
  );

export const BuyerOfferAnalysisProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { offerCoachId } = useParams();
  const [isEditingAgentNotes, setIsEditingAgentNotes] = useState(false);
  const { data: buyerOfferAnalysisData, loading: buyerOfferAnalysisLoading } =
    useGetBuyerOfferAnalysisQuery({
      variables: {
        uuid: offerCoachId ?? '',
      },
      skip: !offerCoachId,
    });

  const buyerOfferAnalysis = useMemo(
    () => buyerOfferAnalysisData?.getBuyerOfferAnalysis,
    [buyerOfferAnalysisData]
  );

  const listing = useMemo(
    () => buyerOfferAnalysis?.listing,
    [buyerOfferAnalysis]
  );

  // Contains all the logic for updating Indigo and Agent Offer Recs on the buyer offer analysis
  const {
    title,
    setTitle,
    updateTitle,
    agentNotes,
    setAgentNotes,
    updateAgentNotes,
    updateIndigoOfferRecs,
    isChangesSaved,
    offerRecsLoading,
    agentContingencies,
    setAgentContingencies,
    agentKeyTerms,
    setAgentKeyTerms,
    isCalculatingOfferScore,
  } = useUpdateBuyerOfferAnalysis({ buyerOfferAnalysis, listing });

  // Contains all the update logic for the edit comps table and map
  const {
    comparableSetLoading,
    refetchComparableSet,
    initialSelectedComp,
    isCompsUpdated,
    setIsCompsUpdated,
    comparableSet,
    onEditCompsModalOpen,
    onEditCompsModalClose,
    isEditCompsModalOpen,
    comps,
    setComps,
  } = useBuyerOfferAnalysisComps({
    buyerOfferAnalysis,
    updateIndigoOfferRecs,
  });

  useOfferRecsActions(
    buyerOfferAnalysis?.indigoOfferRecs,
    agentKeyTerms,
    setAgentKeyTerms
  );

  return (
    <BuyerOfferAnalysisContext.Provider
      value={{
        offerRecs: buyerOfferAnalysis?.indigoOfferRecs,
        buyerOfferAnalysis,
        listing,
        buyerOfferAnalysisLoading,
        comparableSetLoading,
        offerRecsLoading,
        comparableSet,
        isEditCompsModalOpen,
        onEditCompsModalOpen,
        onEditCompsModalClose,
        comps,
        setComps,
        refetchComparableSet,
        initialSelectedComp,
        isCompsUpdated,
        setIsCompsUpdated,
        isChangesSaved,
        title,
        setTitle,
        updateTitle,
        agentNotes,
        setAgentNotes,
        updateAgentNotes,
        agentContingencies,
        setAgentContingencies,
        agentKeyTerms,
        setAgentKeyTerms,
        isEditingAgentNotes,
        setIsEditingAgentNotes,
        offerScore: buyerOfferAnalysis?.offerScore,
        isCalculatingOfferScore,
      }}
    >
      {children}
    </BuyerOfferAnalysisContext.Provider>
  );
};

export const useBuyerOfferAnalysisContext = () => {
  return useContext(BuyerOfferAnalysisContext);
};
