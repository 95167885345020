import { Box } from '@chakra-ui/react';
import { GeocodingFeature } from '@mapbox/search-js-core';
import { FC, memo, useCallback, useRef } from 'react';
import {
  Layer,
  Map,
  MapMouseEvent,
  MapProps,
  MapRef,
  Source,
} from 'react-map-gl';
import { MapboxGeocoder } from '~/apps/consumer/components/MapboxGeocoder/MapboxGeocoder';
import { DEFAULT_POSITION } from '~/apps/consumer/components/MapSearch/constants';
import { colors } from '~/common/theme';

interface ZipCodesGeoMapProps extends Omit<MapProps, 'projection' | 'terrain'> {
  mapRef: React.RefObject<MapRef>;
  zipCodesGeoJson?: GeoJSON.FeatureCollection | null;
  handleMapClick?: (event: MapMouseEvent) => void;
  setMapSuggestions?: React.Dispatch<React.SetStateAction<GeocodingFeature[]>>;
}

export const ZipCodesGeoMap: FC<ZipCodesGeoMapProps> = memo(
  function ZipCodesGeoMap({
    mapRef,
    zipCodesGeoJson,
    handleMapClick,
    setMapSuggestions,
    ...mapProps
  }) {
    const hoveredPolygonId = useRef<number>();
    const hoveredPolygonSource = useRef<string>();

    const onMouseMove = useCallback((event: MapMouseEvent) => {
      const map = event.target;
      const features = map.queryRenderedFeatures(event.point, {
        layers: ['multi-polygon'],
      });
      const feature = features?.[0];

      if (
        hoveredPolygonId.current &&
        hoveredPolygonSource.current &&
        feature?.id !== hoveredPolygonId.current
      ) {
        map.setFeatureState(
          {
            id: hoveredPolygonId.current,
            source: hoveredPolygonSource.current,
          },
          { hover: false }
        );
      }

      if (feature?.id) {
        hoveredPolygonId.current = feature.id as number;
        hoveredPolygonSource.current = feature.source;
        map.setFeatureState(
          { id: feature.id, source: feature.source },
          { hover: true }
        );
      }
    }, []);

    return (
      <Map
        ref={mapRef}
        boxZoom={false}
        initialViewState={{
          longitude: DEFAULT_POSITION.lng,
          latitude: DEFAULT_POSITION.lat,
          zoom: DEFAULT_POSITION.zoom,
        }}
        interactiveLayerIds={['multi-polygon']}
        mapStyle="mapbox://styles/mapbox/navigation-guidance-night-v4"
        mapboxAccessToken={window.ENVS.mapboxApiKey}
        minZoom={3}
        testMode={window.ENVS.environment === 'e2e'}
        onClick={handleMapClick}
        onMouseMove={onMouseMove}
        {...mapProps}
      >
        <Source data={zipCodesGeoJson} id="zip-codes-polygon" type="geojson">
          <Layer
            id="multi-polygon"
            paint={{
              'fill-opacity-transition': {
                duration: 300,
              },
              'fill-opacity': [
                'match',
                ['get', 'isSelected'],
                'true',
                0.8,
                'false',
                0.3,
                0.3,
              ],
              'fill-color': [
                'match',
                ['get', 'isSelected'],
                'true',
                colors.purple[600],
                'false',
                '#000000',
                '#000000',
              ],
            }}
            type="fill"
          />
          <Layer
            id="line"
            paint={{
              'line-color': colors.purple[500],
              'line-width-transition': {
                duration: 300,
              },
              'line-width': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                6,
                2,
              ],
            }}
            type="line"
          />
          <Layer
            id="points-label"
            layout={{
              'text-field': ['get', 'zipCode'],
              'text-anchor': 'center',
              'text-offset': [0, 1],
              'text-size': 20,
              'text-justify': 'center',
              'text-variable-anchor': ['center'],
            }}
            paint={{
              'text-color': [
                'match',
                ['get', 'isSelected'],
                'true',
                '#000000',
                'false',
                '#ffffff',
                '#ffffff',
              ],
            }}
            type="symbol"
          />
        </Source>

        <Box
          bgColor="indigo.900"
          borderRadius="sm"
          left={4}
          position="absolute"
          top={4}
          width={{
            base: '300px',
            md: '400px',
          }}
        >
          <MapboxGeocoder
            clearInputOnSelect={true}
            flyToSuggestion={false}
            mapRef={mapRef}
            onRetrieve={(suggestion) => {
              const city = suggestion.properties.context.place?.name;
              const region = suggestion.properties.context
                .region as unknown as {
                region_code: string;
              };

              if (city && region.region_code) {
                setMapSuggestions?.((prev) => {
                  const newSuggestions = [...prev, suggestion];
                  const uniqueSuggestions = new window.Map(
                    newSuggestions.map((suggestion) => {
                      return [suggestion.id, suggestion];
                    })
                  );

                  return Array.from(uniqueSuggestions.values());
                });
              } else {
                setMapSuggestions?.([]);
              }
            }}
          />
        </Box>
      </Map>
    );
  }
);
