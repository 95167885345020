import { Flex, Text } from '@chakra-ui/react';
import {
  KeyTermType,
  KeyTermUnit,
  MarketDataPercentages,
} from '@client/graphql/__generated__/types';
import isNil from 'lodash/isNil';
import { formatPercent } from '~/common/utils/formatter';
import { formatToCurrency } from '~/services/document/utils/number';

export const formatRangeValue = (value: number, unit: KeyTermUnit) => {
  if (unit === KeyTermUnit.USD) {
    return formatRangeCurrencyValue(value);
  } else if (unit === KeyTermUnit.DAYS) {
    return `${value} days`;
  } else {
    return value;
  }
};

export const formatRangeCurrencyValue = (value?: number) => {
  if (isNil(value)) return;

  const notation = value > 10000 ? 'compact' : 'standard';

  // Properly format millions values with 2 decimal places
  // 1,950,000 should be 1.95M but 19,500,000 should be 19.5M
  const millionsValue = value / 1000000;
  const decimals =
    value >= 1000000
      ? millionsValue < 10
        ? 2
        : millionsValue < 100
          ? 1
          : 0
      : 0;

  return formatToCurrency(value, decimals, notation);
};

export const formatDistance = (distanceMiles: number) => {
  return distanceMiles > 30
    ? '> 30 miles'
    : `${distanceMiles.toFixed(1)} miles`;
};

export const getDotColor = ({
  unit,
  value,
  range,
  type,
}: {
  value?: number | null;
  range?: number[];
  unit?: KeyTermUnit;
  type?: KeyTermType;
}) => {
  if (isNil(value) || isNil(range) || range.length < 2) return 'gray.500';

  if (unit === KeyTermUnit.USD) {
    if (type === KeyTermType.SELLER_CONCESSIONS) {
      return value <= range[1] ? 'green.500' : 'red.500';
    }

    return value < range[0]
      ? 'red.500'
      : value > range[1]
        ? 'yellow.500'
        : 'green.500';
  } else {
    return value <= range[1] ? 'green.500' : 'red.500';
  }
};

export const getMarketDataDisplay = (
  marketData?: MarketDataPercentages,
  suffixOverride?: string
) => {
  if (isNil(marketData)) {
    return null;
  }

  const value = formatPercent(marketData.yes);
  const suffix = suffixOverride || `have contingency`;

  return (
    <Flex alignItems="baseline" gap={1}>
      <Text fontSize="24px" fontWeight="medium" lineHeight="1">
        {value}
      </Text>
      <Text fontSize="18px" fontWeight="medium" lineHeight="1">
        {suffix}
      </Text>
    </Flex>
  );
};
