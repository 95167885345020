import { chakra, Flex, keyframes, Text } from '@chakra-ui/react';
import { Keyframes } from '@emotion/react';
import { isNil } from 'lodash';
import { useEffect, useState } from 'react';
import { LogoSpinner } from '~/common/components/LogoSpinner/LogoSpinner';
import { ValidationTag } from '~/common/components/ValidationTag';

// Create a function to generate keyframes for the score animation
const createScoreAnimation = (startScore: number, endScore: number) => {
  const steps = 20; // Number of steps in the animation
  const keyframeSteps: string[] = [];

  for (let i = 0; i <= steps; i++) {
    const progress = i / steps;
    const currentScore = startScore + (endScore - startScore) * progress;
    const percentage = currentScore / 10;
    const angleInRadians = percentage * Math.PI;

    const leftRatio = (1 - Math.cos(angleInRadians)) / 2;
    const bottomRatio = Math.sin(angleInRadians);
    const left = `calc(${leftRatio * 100}% - ${15 + 10 * leftRatio}px)`;
    const bottom = `calc(${bottomRatio * 100}% - ${bottomRatio * 10}px)`;
    const rotation = percentage * 180;

    keyframeSteps.push(`${(progress * 100).toFixed(0)}% {
      left: ${left};
      bottom: ${bottom};
      transform: rotate(${rotation}deg);
    }`);
  }

  return keyframes`${keyframeSteps.join('\n')}`;
};

export const OfferScoreChart = ({
  score,
  loading,
}: {
  score?: number;
  loading?: boolean;
}) => {
  const [localScore, setLocalScore] = useState<number | undefined>(score);
  const [prevScore, setPrevScore] = useState<number | undefined>(score);
  const [prevScoreDifference, setPrevScoreDifference] = useState<
    number | undefined
  >(undefined);
  const [showDifference, setShowDifference] = useState(false);
  const [animation, setAnimation] = useState<Keyframes | undefined>(undefined);
  const [scoreLeft, setScoreLeft] = useState<string | undefined>(undefined);
  const [scoreBottom, setScoreBottom] = useState<string | undefined>(undefined);
  const [scoreAngle, setScoreAngle] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (score !== undefined && prevScore !== undefined && score !== prevScore) {
      const newAnimation = createScoreAnimation(prevScore, score);
      setAnimation(newAnimation);
      setPrevScoreDifference(score - prevScore);
      setTimeout(() => {
        setPrevScore(score);
        setAnimation(undefined);
        setShowDifference(true);
        setTimeout(() => {
          setShowDifference(false);
        }, 2000);
      }, 1000);
    }
    const scorePercentage = (score || 0) / 10;
    const scoreAngle = scorePercentage * 180;

    const scoreBottomRatio = Math.sin(scorePercentage * Math.PI);

    // For a proper half-circle motion from left to right:
    const scoreLeftRatio = (1 - Math.cos(scorePercentage * Math.PI)) / 2;
    const scoreLeft = `calc(${scoreLeftRatio * 100}% - ${
      15 + 10 * scoreLeftRatio
    }px)`;

    const scoreBottom = `calc(${scoreBottomRatio * 100}% - ${
      scoreBottomRatio * 10
    }px)`;
    setScoreLeft(scoreLeft);
    setScoreBottom(scoreBottom);
    setScoreAngle(scoreAngle);
    setLocalScore(score);
  }, [score, prevScore]);

  return (
    <Background>
      <Mask>
        <Flex
          alignItems="baseline"
          gap={1}
          marginTop="10px"
          position="relative"
        >
          {!loading &&
            (!isNil(score) ? (
              <>
                <ValidationTag
                  backgroundColor={
                    !!prevScoreDifference && prevScoreDifference > 0
                      ? 'rgba(32, 89, 61, 0.40)'
                      : 'rgba(114, 35, 42, 0.40)'
                  }
                  isError={!!prevScoreDifference && prevScoreDifference < 0}
                  isSuccess={!!prevScoreDifference && prevScoreDifference > 0}
                  opacity={showDifference ? 1 : 0}
                  position="absolute"
                  right="0"
                  size="sm"
                  top="2"
                  transition="opacity 0.5s"
                >
                  {prevScoreDifference && prevScoreDifference < 0 ? '-' : '+'}
                  {prevScoreDifference && Math.abs(prevScoreDifference)}
                </ValidationTag>
                <Text
                  color={animation ? 'whiteAlpha.600' : 'white'}
                  fontSize="50px"
                  fontWeight="medium"
                >
                  {animation ? prevScore : localScore}
                </Text>
                <Text color="whiteAlpha.600" fontSize="lg" fontWeight="medium">
                  / 10
                </Text>
              </>
            ) : (
              <Text color="whiteAlpha.600" fontSize="50px" fontWeight="medium">
                TBD
              </Text>
            ))}
          {!!loading && (
            <Flex
              alignItems="center"
              direction="column"
              height="100%"
              justifyContent="flex-end"
            >
              <LogoSpinner />
              <Text color="whiteAlpha.600" fontWeight="medium">
                Calculating
              </Text>
            </Flex>
          )}
        </Flex>
      </Mask>
      {!isNil(score) && !loading && (
        <Flex
          animation={animation ? `${animation} 1s forwards` : undefined}
          background="white"
          borderRadius="8px"
          height="10px"
          position="absolute"
          width="40px"
          // If no animation is set, use the calculated position
          {...(!animation && {
            bottom: scoreBottom,
            left: scoreLeft,
            transform: `rotate(${scoreAngle}deg)`,
          })}
        />
      )}
    </Background>
  );
};

export const Background = chakra(Flex, {
  baseStyle: {
    position: 'relative',
    height: '80px',
    background:
      'linear-gradient(90deg, rgba(255,79,93,1) 0%, rgba(255,203,0,1) 50%, rgba(71,198,137,1) 100%);',
    borderRadius: '150px 150px 0 0',
    textAlign: 'center',
    width: '150px',
  },
});

export const Mask = chakra(Flex, {
  baseStyle: {
    position: 'absolute',
    height: 'calc(100% - 10px)',
    width: '130px',
    backgroundColor: 'bg.main',
    borderRadius: '150px 150px 0 0',
    top: '10px',
    left: '10px',
    alignItems: 'bottom',
    justifyContent: 'center',
  },
});
