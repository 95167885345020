import { Flex, Icon, Text } from '@chakra-ui/react';
import {
  ConfidenceLevel,
  MarketDataPercentages,
  MarketDataSourceType,
  UserObject,
} from '@client/graphql/__generated__/types';
import { CircleAlertIcon } from 'lucide-react';
import { ToggleButtons } from '~/common/components/ToggleButtons/ToggleButtons';
import { SignalStrength } from '~/common/icons/SignalIcon';
import { COLLAPSED_ROW_HEIGHT } from './constants';
import { NotesPopover } from './NotesPopover';
import { SourceTags } from './SourceTags';
import { getMarketDataDisplay } from './utils';

interface ContingenciesRowProps {
  label: string;
  source?: MarketDataSourceType;
  confidence?: ConfidenceLevel;
  marketData?: MarketDataPercentages;
  distanceMiles?: number;
  timePeriodMonths?: number;
  errorMessage?: string;
  value?: boolean;
  onUpdateValue?: (value: boolean) => void;
  notes?: string | null;
  onUpdateNotes?: (notes: string | null) => void;
  buyersAgentUser?: Partial<Pick<UserObject, 'fullName' | 'profilePicture'>>;
}

export const ContingenciesRow = ({
  label,
  marketData,
  source,
  confidence,
  distanceMiles,
  timePeriodMonths,
  errorMessage,
  value = false,
  onUpdateValue,
  notes,
  onUpdateNotes,
  buyersAgentUser,
}: ContingenciesRowProps) => {
  const marketDataDisplay = marketData
    ? getMarketDataDisplay(marketData)
    : undefined;

  const sourceLabel = source?.toLowerCase()?.split('_')?.join(' ');

  return (
    <Flex
      borderBottom="1px solid"
      borderBottomColor="whiteAlpha.200"
      direction="column"
      justifyContent="center"
      px={4}
    >
      <Flex
        alignItems="center"
        gap={6}
        height={COLLAPSED_ROW_HEIGHT}
        py={4}
        transition="height 0.3s ease-in-out"
      >
        <Flex flexBasis={0} flexGrow={1} height="100%">
          <Flex direction="column" height="100%" justifyContent="space-between">
            <Text fontWeight="medium" textTransform="capitalize">
              {label}
            </Text>
          </Flex>
        </Flex>
        <Flex flexBasis={0} flexGrow={4} height="100%">
          <Flex
            direction="column"
            height="100%"
            justifyContent="space-between"
            width="100%"
          >
            <Flex height="100%">
              <Flex flexBasis={0} flexGrow={2} height="100%">
                {marketData && (
                  <Flex direction="column" gap={1}>
                    {marketDataDisplay}
                    <SourceTags
                      confidence={confidence as unknown as SignalStrength}
                      distanceMiles={distanceMiles}
                      isExpanded={false}
                      sourceLabel={sourceLabel}
                      timePeriodMonths={timePeriodMonths}
                    />
                  </Flex>
                )}
                {errorMessage && (
                  <Flex alignItems="center" gap={1}>
                    <Icon as={CircleAlertIcon} color="red.500" />
                    <Text>{errorMessage}</Text>
                  </Flex>
                )}
              </Flex>
              <Flex flexBasis={0} flexGrow={2} gap={4} justifyContent="center">
                <ToggleButtons
                  activeButton={value}
                  activeButtonColor="purpleGradient"
                  activeButtonTextColor="white"
                  buttons={[
                    {
                      label: 'Yes',
                      value: true,
                    },
                    {
                      label: 'No',
                      value: false,
                    },
                  ]}
                  setActiveButton={(value) => onUpdateValue?.(value)}
                  size="lg"
                />
                <Flex paddingTop={3}>
                  <NotesPopover
                    label={label}
                    noteUser={buyersAgentUser}
                    notes={notes ?? ''}
                    onChange={(notes) => onUpdateNotes?.(notes)}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};
