import { OfferRecsFragment } from '@client/graphql/__generated__/types';
import { CopilotActions } from '@common/enums';
import { useCopilotAction } from '@copilotkit/react-core';
import React from 'react';
import { AgentKeyTermMap } from '~/apps/consumer/components/BuyerOfferAnalysis/Context/utils';
import { objectToCsv } from '~/common/utils/formatter';
import { useCopilotReadableContext } from '../hooks/useCopilotReadableContext';

export const useOfferRecsActions = (
  offerRecs?: OfferRecsFragment,
  keyTerms?: AgentKeyTermMap,
  setKeyTerms?: (value: React.SetStateAction<AgentKeyTermMap>) => void
) => {
  useCopilotReadableContext({
    description: 'Current offer key term values',
    value: keyTerms,
    categories: ['offerRecs'],
  });

  useCopilotReadableContext({
    description: 'Offer key terms recommendations data',
    value: offerRecs?.keyTerms,
    categories: ['offerRecs'],
  });

  useCopilotReadableContext({
    description: 'Offer competitiveness data',
    value: offerRecs?.competitiveness,
    categories: ['offerRecs'],
  });

  useCopilotAction({
    name: CopilotActions.updateOfferRecsKeyTerms,
    description: [
      'Efficiently modify and complete the key terms of an offer using detailed recommendations data.',
      'Be specific in the updates required and ensure the revisions align with the recommended guidelines for maximum accuracy.',
    ].join(' '),
    available: !!offerRecs?.keyTerms?.length ? 'enabled' : 'disabled',
    parameters: [
      {
        name: 'keyTerms',
        type: 'object[]',
        description: 'Array of key terms to update',
        required: true,
        attributes: [
          {
            name: 'keyTermType',
            type: 'string',
            description: 'Type of key term to update',
            required: true,
          },
          {
            name: 'keyTermUnit',
            type: 'string',
            description: 'Unit of the key term',
            required: false,
          },
          {
            name: 'keyTermValue',
            type: 'string',
            description: 'Value of the key term',
            required: true,
          },
        ],
      },
      {
        name: 'shouldUpdate',
        description: 'Whether to update the key terms of the offer',
        type: 'boolean',
        required: true,
      },
    ],
    handler: (params) => {
      if (!params.shouldUpdate) {
        return objectToCsv(params.keyTerms);
      }

      setKeyTerms?.((prev) => {
        const updated = { ...prev };
        params.keyTerms.forEach((keyTerm) => {
          const keyTermType = keyTerm.keyTermType;
          updated[keyTermType] = {
            value: Number(keyTerm.keyTermValue),
          };
        });

        return updated;
      });

      return `Provide short summary of the updated key terms in a list format. ${objectToCsv(
        params.keyTerms
      )}`;
    },
  });
};
