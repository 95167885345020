import {
  Box,
  HStack,
  Icon,
  Progress,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from '@chakra-ui/react';
import {
  PerAgentOfferAcceptRateFragment,
  PerAgentTransactionStatsFragment,
} from '@client/graphql/__generated__/types';
import { GeocodingFeature } from '@mapbox/search-js-core';
import { GripHorizontal, GripVertical } from 'lucide-react';
import { FC, useMemo, useRef, useState } from 'react';
import { MapRef } from 'react-map-gl';
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels';
import { ComingSoon } from '~/apps/consumer/components/ComingSoon';
import { ZipCodesGeoMap } from '~/apps/consumer/components/Map/ZipCodesGeoMap/ZipCodesGeoMap';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useOrgPerformanceMetricsAction } from '~/common/copilot/actions/useOrgPerformanceMetricsAction';
import { AgentsList } from './components/AgentsList';
import { Filters } from './components/Filters';
import { TeamPerformancePanel } from './components/TeamPerformancePanel';
import { TopPerformers } from './components/TopPerformers';
import { useOrgPerformance } from './hooks/useOrgPerformance';
import { usePanel } from './hooks/usePanel';
import { usePerformanceMetrics } from './hooks/usePerformanceMetrics';

export const OrganizationPerformancePage: FC = () => {
  const mapRef = useRef<MapRef>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [mapSuggestions, setMapSuggestions] = useState<GeocodingFeature[]>([]);
  const { panelMinSizes, isRightPanelLg, onLayout, isMobile } =
    usePanel(mapRef);

  const {
    zipCodesGeoJson,
    allTransactions,
    handleMapClick,
    selectedZipCodes,
    setSelectedZipCodes,
    transactionFilters,
    setTransactionFilters,
    perAgentOfferAcceptRateData,
    perAgentTransactionStatsData,
    perAgentTransactionStatsDataBySelectedZipCodes,
    perAgentOfferAcceptRateDataBySelectedZipCodes,
    isLoading,
  } = useOrgPerformance(mapSuggestions);

  const agentPerformanceData = useMemo(() => {
    return perAgentOfferAcceptRateData?.perAgentOfferAcceptRate.map((item) => ({
      ...item,
      ...perAgentTransactionStatsData?.perAgentTransactionStats.find(
        (stat) => stat.agent_name === item.agent_name
      ),
    })) as (Omit<PerAgentOfferAcceptRateFragment, '__typename'> &
      Omit<PerAgentTransactionStatsFragment, '__typename'>)[];
  }, [
    perAgentOfferAcceptRateData?.perAgentOfferAcceptRate,
    perAgentTransactionStatsData?.perAgentTransactionStats,
  ]);

  useOrgPerformanceMetricsAction({
    orgTransactionStats: allTransactions ?? [],
    agentPerformanceData: agentPerformanceData ?? [],
  });

  const filteredTransactions = useMemo(
    () =>
      allTransactions?.filter(
        (transaction) =>
          selectedZipCodes.length === 0 ||
          selectedZipCodes.includes(transaction.zip_code)
      ),
    [allTransactions, selectedZipCodes]
  );

  const { teamPerformance, topPerformers } = usePerformanceMetrics(
    filteredTransactions,
    selectedZipCodes.length
      ? perAgentOfferAcceptRateDataBySelectedZipCodes?.perAgentOfferAcceptRate
      : perAgentOfferAcceptRateData?.perAgentOfferAcceptRate,
    selectedZipCodes.length
      ? perAgentTransactionStatsDataBySelectedZipCodes?.perAgentTransactionStats
      : perAgentTransactionStatsData?.perAgentTransactionStats
  );

  return (
    <PanelGroup
      direction={isMobile ? 'vertical' : 'horizontal'}
      onLayout={onLayout}
    >
      <Panel
        defaultSize={Math.floor(100 - panelMinSizes[1])}
        minSize={panelMinSizes[0]}
      >
        <Box
          flexGrow={{ base: 0, md: 1 }}
          flexShrink={{ base: 1, md: 0 }}
          height="100%"
          position="relative"
          width="100%"
        >
          {isLoading && (
            <Progress
              isIndeterminate
              left={0}
              position="absolute"
              right={0}
              size="xs"
              top={0}
              zIndex={1}
            />
          )}
          {!zipCodesGeoJson && isLoading && (
            <HStack
              alignItems="center"
              bottom={0}
              justifyContent="center"
              left={0}
              position="absolute"
              right={0}
              top={0}
              zIndex={1}
            >
              <Box bgColor="blackAlpha.800" p={4} rounded="md">
                <CenterSpinner />
              </Box>
            </HStack>
          )}

          <ZipCodesGeoMap
            handleMapClick={handleMapClick}
            mapRef={mapRef}
            setMapSuggestions={setMapSuggestions}
            zipCodesGeoJson={zipCodesGeoJson}
          />
        </Box>
      </Panel>
      <PanelResizeHandle
        style={{
          position: 'relative',
          width: isMobile ? '100%' : '10px',
          height: isMobile ? '10px' : '100%',
        }}
      >
        <Box
          bottom={isMobile ? '0' : '-20px'}
          left={isMobile ? '50%' : '-8px'}
          position="absolute"
          top={isMobile ? '-10px' : '50%'}
        >
          <Box bgColor="indigo.800" pt={1} px={1} rounded="sm">
            <Icon as={isMobile ? GripHorizontal : GripVertical} />
          </Box>
        </Box>
      </PanelResizeHandle>
      <Panel defaultSize={panelMinSizes[1]} minSize={panelMinSizes[1]}>
        <Box
          ref={scrollRef}
          bgColor="indigo.700"
          flexGrow={{ base: 0, md: 1 }}
          flexShrink={{ base: 1, md: 0 }}
          height={{ base: '100%', md: '100dvh' }}
          overflow="auto"
          width="100%"
        >
          <Tabs isLazy variant="document">
            <Box
              backdropFilter="blur(2px)"
              bgColor="#151532f2"
              pb={4}
              position={{ base: 'relative', md: 'sticky' }}
              top={0}
              zIndex={2}
            >
              <TabList bgColor="indigo.900" gap={0}>
                <Tab
                  borderColor="whiteAlpha.200"
                  borderRightWidth={1}
                  {...tabProps}
                >
                  Buying
                </Tab>
                <Tab {...tabProps} isDisabled>
                  Listing
                  <ComingSoon
                    as="sup"
                    color="transparent"
                    fontSize="8px"
                    fontWeight="medium"
                    ml={1}
                    position="relative"
                    size={undefined}
                    textAlign="left"
                    textTransform="uppercase"
                    top="-0.5em"
                  />
                </Tab>
                <Tab {...tabProps} isDisabled>
                  Market Trends
                  <ComingSoon
                    as="sup"
                    color="transparent"
                    fontSize="8px"
                    fontWeight="medium"
                    ml={1}
                    position="relative"
                    size={undefined}
                    textAlign="left"
                    textTransform="uppercase"
                    top="-0.5em"
                  />
                </Tab>
              </TabList>

              <Filters
                selectedZipCodes={selectedZipCodes}
                setSelectedZipCodes={setSelectedZipCodes}
                setSuggestions={setMapSuggestions}
                setTransactionFilters={setTransactionFilters}
                suggestions={mapSuggestions}
                transactionFilters={transactionFilters}
              />
            </Box>

            <TabPanels>
              <TabPanel as={VStack} pt={1} px={0} spacing={4}>
                <Stack
                  alignItems="stretch"
                  flexDir={isRightPanelLg ? 'row' : 'column'}
                  px={4}
                  spacing={{ base: 4, lg: 6 }}
                  width="100%"
                >
                  <TeamPerformancePanel teamPerformance={teamPerformance} />
                  <TopPerformers topPerformers={topPerformers} />
                </Stack>

                <AgentsList
                  perAgentOfferAcceptRate={
                    selectedZipCodes.length
                      ? perAgentOfferAcceptRateDataBySelectedZipCodes?.perAgentOfferAcceptRate
                      : perAgentOfferAcceptRateData?.perAgentOfferAcceptRate
                  }
                  perAgentTransactionStats={
                    selectedZipCodes.length
                      ? perAgentTransactionStatsDataBySelectedZipCodes?.perAgentTransactionStats
                      : perAgentTransactionStatsData?.perAgentTransactionStats
                  }
                  scrollElement={scrollRef.current}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Panel>
    </PanelGroup>
  );
};

const tabProps = {
  _selected: {
    bg: 'indigo.700',
    color: 'white',
  },
  _disabled: {
    opacity: 0.9,
    cursor: 'default',
  },
  display: 'flex',
  alignItems: 'center',
  fontWeight: '500',
  px: { base: 3, md: 4 },
  py: { base: 2, md: 3 },
  lineHeight: '1',
};
