import {
  PerAgentOfferAcceptRateFragment,
  PerAgentTransactionStatsFragment,
} from '@client/graphql/__generated__/types';
import EventEmitter from 'eventemitter3';
import { range } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { OrderBy } from '~/services/document/common/GridTable/GridTable';

interface UseAgentListOptions {
  scrollElement?: HTMLDivElement | null;
  perAgentOfferAcceptRate?: PerAgentOfferAcceptRateFragment[];
  perAgentTransactionStats?: PerAgentTransactionStatsFragment[];
}

export const useAgentList = ({
  scrollElement,
  perAgentOfferAcceptRate,
  perAgentTransactionStats,
}: UseAgentListOptions) => {
  const [orderBy, setOrderBy] = useState<OrderBy | null>({
    key: 'total_volume',
    order: 'desc',
  });
  const visibleRowsRef = useRef<string[]>(range(30).map((i) => String(i)));
  const extendedVisibleRowsRef = useRef<string[]>(
    range(30).map((i) => String(i))
  );
  const visibleEventEmitter = useMemo(() => new EventEmitter(), []);

  const intersectionObserver = useMemo(() => {
    if (!scrollElement) return null;

    const onIntersection = (entries: IntersectionObserverEntry[]) => {
      const newVisibleRows = entries.filter(
        (entry) => entry.intersectionRatio > 0
      );
      const newHiddenRows = entries.filter(
        (entry) => entry.intersectionRatio === 0
      );

      const prevFiltered = visibleRowsRef.current.filter(
        (index) =>
          !newHiddenRows.some(
            (entry) =>
              (entry.target as HTMLDivElement).dataset.index === String(index)
          )
      );

      visibleRowsRef.current = Array.from(
        new Set([
          ...prevFiltered,
          ...newVisibleRows.map(
            (entry) => (entry.target as HTMLDivElement).dataset.index
          ),
        ] as string[])
      );

      const max = Math.max(...visibleRowsRef.current.map((i) => parseInt(i)));
      const min = Math.min(...visibleRowsRef.current.map((i) => parseInt(i)));

      extendedVisibleRowsRef.current = [
        ...range(min - 5, min).map((i) => String(i)),
        ...visibleRowsRef.current,
        ...range(max + 1, max + 10).map((i) => String(i)),
      ];

      visibleEventEmitter.emit(
        'visibleRowsUpdated',
        extendedVisibleRowsRef.current
      );
    };

    return new IntersectionObserver(onIntersection, {
      root: scrollElement,
      threshold: [0, 0.2, 0.4, 0.6, 0.8, 1],
    });
  }, [scrollElement, visibleEventEmitter]);

  const agents = useMemo(() => {
    return perAgentOfferAcceptRate
      ?.map((agent) => {
        const transactionStats = perAgentTransactionStats?.find(
          (o) => o.agent_name === agent.agent_name
        );

        return {
          ...agent,
          ...transactionStats,
        };
      })
      .sort((a, b) => {
        if (!orderBy) return b.total_offers - a.total_offers;

        return orderBy.order === 'asc'
          ? (a[orderBy.key] ?? 0) - (b[orderBy.key] ?? 0)
          : (b[orderBy.key] ?? 0) - (a[orderBy.key] ?? 0);
      });
  }, [perAgentOfferAcceptRate, perAgentTransactionStats, orderBy]);

  const observeRow = useCallback(
    (ref: HTMLDivElement) => {
      if (ref) {
        intersectionObserver?.observe(ref);
      }
    },
    [intersectionObserver]
  );

  const onOrderBy = useCallback((orderBy: OrderBy) => {
    setOrderBy(orderBy);
  }, []);

  return {
    agents,
    observeRow,
    visibleEventEmitter,
    onOrderBy,
    orderBy,
  };
};
