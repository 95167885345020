import { flattenObject } from '@common/utils/object';
import { json2csv } from 'json-2-csv';
import isNil from 'lodash/isNil';

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';
export const DEFAULT_DATETIME_FORMAT = 'MM/DD/YYYY h:mm a';

export type NumberFormatterType = 'currency' | 'percent';

export interface NumberFormatterOptions {
  maxPrecision?: number;
  type?: NumberFormatterType;
  unitSuffix?: string;
  notation?: Intl.NumberFormatOptions['notation'];
}

export const numberFormatter = (
  value: number | undefined,
  { maxPrecision = 2, type, unitSuffix, notation }: NumberFormatterOptions
) => {
  if (isNil(value)) {
    return value;
  }

  const formattedValue = value.toLocaleString('en-US', {
    maximumFractionDigits: maxPrecision,
    minimumFractionDigits: type === 'currency' ? maxPrecision : 0,
    style: type,
    currency: type === 'currency' ? 'USD' : undefined,
    notation,
  });

  return unitSuffix ? `${formattedValue}${unitSuffix}` : formattedValue;
};

export const formatPhoneNumber = (phoneNumber?: string) => {
  if (!phoneNumber) {
    return '';
  }

  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';

    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }

  return phoneNumber;
};

export const formatPrice = (price: number, significantDigits = 3) => {
  const rounded = Number(price.toPrecision(significantDigits));
  const mod3Digits = rounded.toString().length % 3;
  const precision =
    mod3Digits === 0 ? significantDigits - 3 : significantDigits - mod3Digits;

  return numberFormatter(rounded, {
    type: 'currency',
    maxPrecision: precision,
    notation: 'compact',
  });
};

export const formatPercent = (value: number | undefined, precision = 2) => {
  if (isNil(value)) {
    return value;
  }

  return numberFormatter(value, {
    type: 'percent',
    maxPrecision: precision,
  });
};

export const objectToCsv = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  object: Record<string, any> | Record<string, any>[]
) => {
  let csv = '';

  if (Array.isArray(object)) {
    csv = json2csv(object.map((val) => flattenObject(val)));
  } else {
    csv = json2csv([flattenObject(object)]);
  }

  const cleanCsv = csv
    .replace(/\\\./g, '.')
    .split('\n')
    .map((line) => line.split(' ').filter(Boolean).join(' '))
    .filter(Boolean)
    .join('\n');

  return `\`\`\`csv
${cleanCsv}
\`\`\``;
};
