import { Avatar, Heading, Text, Tooltip, VStack } from '@chakra-ui/react';
import {
  PerAgentOfferAcceptRateFragment,
  PerAgentTransactionStatsFragment,
} from '@client/graphql/__generated__/types';
import EventEmitter from 'eventemitter3';
import { FC, memo } from 'react';
import { formatPercent, formatPrice } from '~/common/utils/formatter';
import {
  GridTable,
  GridTableBody,
  GridTableCell,
  GridTableHead,
  GridTableHeadItem,
  GridTableRow,
} from '~/services/document/common/GridTable/GridTable';
import { Null } from '~/services/document/utils/graphql';
import { classify } from '~/services/document/utils/string';
import { useAgentList } from '../hooks/useAgentList';

interface AgentsListProps {
  perAgentTransactionStats?: PerAgentTransactionStatsFragment[];
  perAgentOfferAcceptRate?: PerAgentOfferAcceptRateFragment[];
  scrollElement?: HTMLDivElement | null;
}

export const AgentsList: FC<AgentsListProps> = memo(function AgentsList(props) {
  const { perAgentTransactionStats, perAgentOfferAcceptRate, scrollElement } =
    props;

  const { agents, observeRow, visibleEventEmitter, onOrderBy, orderBy } =
    useAgentList({
      perAgentOfferAcceptRate,
      perAgentTransactionStats,
      scrollElement,
    });

  return (
    <VStack alignItems="stretch" spacing={4} width="100%">
      <Heading as="h2" fontSize="xl" px={4}>
        Agents
      </Heading>

      <GridTable
        gridTemplateColumns="150px auto auto auto auto auto auto auto auto auto auto auto"
        mx={4}
        orderBy={orderBy}
        onOrderBy={onOrderBy}
      >
        <GridTableHead
          backdropFilter="blur(50px)"
          backgroundColor="card.bg"
          borderBottom="none"
          borderColor="card.border"
          borderRadius="12px 12px 0 0"
          borderStyle="solid"
          borderWidth="1px 1px 0 1px"
          boxShadow="0px 0px 1px 0px var(--chakra-colors-card-boxShadow) inset;"
          color="whiteAlpha.600"
          position="sticky"
          textAlign="center"
          textShadow="1px 1px 1px var(--chakra-colors-blackAlpha-400)"
          top={{ base: '-1px', md: '115px' }}
          zIndex={2}
        >
          <GridTableHeadItem textAlign="left">Agent</GridTableHeadItem>
          <GridTableHeadItem orderByKey="submitted_offers">
            Submitted
          </GridTableHeadItem>
          <GridTableHeadItem orderByKey="accepted_offers">
            Accepted
          </GridTableHeadItem>
          <GridTableHeadItem orderByKey="total_volume">
            $ Accepted
          </GridTableHeadItem>
          <GridTableHeadItem orderByKey="offer_accept_rate">
            Accept Rate
          </GridTableHeadItem>
          <GridTableHeadItem orderByKey="average_purchase_price">
            Avg Purchase Price
          </GridTableHeadItem>
          <GridTableHeadItem orderByKey="average_bid_ask">
            Avg Bid/Ask
          </GridTableHeadItem>
          <GridTableHeadItem orderByKey="average_ddf_ratio">
            Avg DDF%
          </GridTableHeadItem>
          <GridTableHeadItem orderByKey="average_dd_days">
            Avg DD Days
          </GridTableHeadItem>
          <GridTableHeadItem orderByKey="average_offer_to_close_days">
            Avg Offer to Close Days
          </GridTableHeadItem>
        </GridTableHead>

        <GridTableBody
          backgroundColor="card.bg"
          borderColor="card.border"
          borderRadius="0 0 12px 12px"
          borderStyle="solid"
          borderWidth="1px"
          boxShadow="0px 0px 1px 0px var(--chakra-colors-card-boxShadow) inset;"
        >
          {agents?.map((agent, index) => (
            <AgentListRow
              key={index}
              agent={agent}
              index={index}
              observeRow={observeRow}
              visibleEventEmitter={visibleEventEmitter}
            />
          ))}
        </GridTableBody>
      </GridTable>
    </VStack>
  );
});

const AgentListRow: FC<{
  agent: Partial<
    Omit<PerAgentTransactionStatsFragment, '__typename'> &
      Omit<PerAgentOfferAcceptRateFragment, '__typename'>
  >;
  index: number;
  observeRow: (node: HTMLDivElement) => void;
  visibleEventEmitter: EventEmitter;
}> = memo(function AgentListRow(props) {
  const { agent, index, observeRow, visibleEventEmitter } = props;

  return (
    <GridTableRow
      key={agent.agent_name}
      _hover={{ bgColor: 'whiteAlpha.200' }}
      fontSize="md"
      height="50px"
      index={index}
      innerRef={observeRow}
      visibleEventEmitter={visibleEventEmitter}
    >
      <GridTableCell maxWidth="150px">
        <Tooltip label={agent.agent_name}>
          <Text
            overflow="hidden"
            textAlign="left"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            <Avatar mr={2} name={classify(agent.agent_name || '')} size="xs" />
            {classify(agent.agent_name || '')}
          </Text>
        </Tooltip>
      </GridTableCell>
      <GridTableCell>{agent.total_offers}</GridTableCell>
      <GridTableCell>{agent.accepted_offers}</GridTableCell>
      <GridTableCell>{formatPrice(agent.total_volume || 0)}</GridTableCell>
      <GridTableCell>{formatPercent(agent.offer_accept_rate)}</GridTableCell>
      <GridTableCell>
        {agent.average_purchase_price === Null
          ? 'N/A'
          : formatPrice(agent.average_purchase_price || 0)}
      </GridTableCell>
      <GridTableCell>
        {agent.average_bid_ask === Null
          ? 'N/A'
          : formatPercent(agent.average_bid_ask || 0)}
      </GridTableCell>
      <GridTableCell>
        {agent.average_ddf_ratio === Null
          ? 'N/A'
          : formatPercent(agent.average_ddf_ratio || 0)}
      </GridTableCell>
      <GridTableCell>
        {agent.average_dd_days === Null
          ? 'N/A'
          : `${agent.average_dd_days || 0} days`}
      </GridTableCell>
      <GridTableCell>
        {agent.average_offer_to_close_days === 0
          ? 'N/A'
          : `${agent.average_offer_to_close_days || 0} days`}
      </GridTableCell>
    </GridTableRow>
  );
});
