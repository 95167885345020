/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCopilotReadable } from '@copilotkit/react-core';
import { useMemo } from 'react';
import { objectToCsv } from '~/common/utils/formatter';

export const useCopilotReadableContext = (
  options: Parameters<typeof useCopilotReadable>[0]
) => {
  const value = useMemo(() => {
    if (typeof options.value === 'object') {
      return objectToCsv(
        options.value as Record<string, any> | Record<string, any>[]
      );
    }

    return options.value;
  }, [options.value]);

  useCopilotReadable({
    ...options,
    value,
  });
};
