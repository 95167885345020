import { Navigate, Outlet } from 'react-router';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { Container } from './Container';

export const EmbeddedDashboardLayout = () => {
  const { selectedOrganization, loadingOrganizations } = useAppContext();

  if (!selectedOrganization && !loadingOrganizations) {
    return <Navigate replace to="/" />;
  }

  return (
    <Container
      bgColor="metabase.night"
      display="flex"
      justifyContent="center"
      maxW="100%"
      overflow="auto"
      p={0}
      px={0}
      py={0}
    >
      <Outlet />
    </Container>
  );
};
