import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { OfferScoreChart } from '../../OfferScore/OfferScoreChart';
import { useBuyerOfferAnalysisContext } from '../Context/BuyerOfferAnalysisContext';

export const OfferScoreCard = () => {
  const { listing, offerScore, isCalculatingOfferScore } =
    useBuyerOfferAnalysisContext();

  if (!listing) {
    return <Skeleton flexBasis={0} flexGrow={1} height="250px" />;
  }

  return (
    <Card flexBasis={0} flexGrow={1} height="250px">
      <CardHeader justifyContent="center">
        <Text color="whiteAlpha.600" textAlign="center">
          Offer Score
        </Text>
      </CardHeader>
      <CardBody>
        <Flex alignItems="center" height="100%" justifyContent="center">
          <OfferScoreChart
            loading={isCalculatingOfferScore}
            score={
              offerScore?.__typename === 'OfferScore'
                ? offerScore.value
                : undefined
            }
          />
        </Flex>
      </CardBody>
    </Card>
  );
};
