import { UsState } from '@client/graphql/__generated__/types';
import { GeocodingFeature } from '@mapbox/search-js-core';
import { useEffect, useMemo } from 'react';
import { useZipCodesGeoInteraction } from '~/apps/consumer/components/Map/ZipCodesGeoMap/useZipCodesGeoInteraction';
import { useOrgPerformanceQueries } from './useOrgPerformanceQueries';

export const useOrgPerformance = (mapSuggestions: GeocodingFeature[]) => {
  const { selectedZipCodes, setSelectedZipCodes, handleMapClick } =
    useZipCodesGeoInteraction();

  const {
    orgTransactionStatsByZipCodeData,
    perAgentTransactionStatsData,
    perAgentOfferAcceptRateData,
    perAgentTransactionStatsDataBySelectedZipCodes,
    perAgentOfferAcceptRateDataBySelectedZipCodes,
    zipCodesGeoData,
    transactionFilters,
    zipCodesGeoFilters,
    setTransactionFilters,
    setZipCodesGeoFilters,
    isLoading,
  } = useOrgPerformanceQueries({
    hasMapSuggestion: !!mapSuggestions?.length,
    selectedZipCodes,
  });

  useEffect(() => {
    if (!mapSuggestions?.length) return;

    const zipCodesGeoFilter = mapSuggestions
      .map((suggestion) => {
        const city = suggestion.properties.context.place?.name ?? '';
        const region = suggestion.properties.context.region as unknown as {
          region_code: string;
        };

        return {
          city,
          state: region.region_code as UsState,
        };
      })
      .reduce(
        (acc, curr) => {
          Object.keys(curr).forEach((key) => {
            if (curr[key]) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              acc[key] = [...(acc[key] ?? []), curr[key]];
            }
          });

          return acc;
        },
        {
          city: [] as string[],
          state: [] as UsState[],
        }
      );

    setZipCodesGeoFilters?.(zipCodesGeoFilter);
  }, [mapSuggestions, setZipCodesGeoFilters]);

  const allTransactions =
    orgTransactionStatsByZipCodeData?.orgTransactionStatsByZipCode;

  const transactionZipCodes = useMemo(() => {
    return new Set(allTransactions?.map((zipCode) => zipCode.zip_code) ?? []);
  }, [allTransactions]);

  const zipCodesGeoJson: GeoJSON.FeatureCollection | null = useMemo(() => {
    if (!zipCodesGeoData?.zipCodesGeo) return null;

    return {
      type: 'FeatureCollection',
      features: zipCodesGeoData.zipCodesGeo
        .filter((zipCode) => transactionZipCodes.has(zipCode.zip_code))
        .map((zipCode) => {
          return {
            type: 'Feature',
            id: zipCode.zip_code,
            properties: {
              zipCode: zipCode.zip_code,
              city: zipCode.city,
              isSelected: String(selectedZipCodes?.includes(zipCode.zip_code)),
            },
            geometry: {
              type: 'MultiPolygon',
              coordinates: zipCode.geo_shape,
            },
          };
        }),
    };
  }, [zipCodesGeoData?.zipCodesGeo, transactionZipCodes, selectedZipCodes]);

  return {
    zipCodesGeoJson,
    allTransactions,
    transactionFilters,
    zipCodesGeoFilters,
    setZipCodesGeoFilters,
    selectedZipCodes,
    handleMapClick,
    setSelectedZipCodes,
    setTransactionFilters,
    perAgentTransactionStatsData,
    perAgentOfferAcceptRateData,
    perAgentTransactionStatsDataBySelectedZipCodes,
    perAgentOfferAcceptRateDataBySelectedZipCodes,
    isLoading,
  };
};
