import { Flex } from '@chakra-ui/react';
import { UserObject } from '@client/graphql/__generated__/types';
import { forwardRef } from 'react';
import { UserAvatar } from '../User/UserAvatar';

interface AgentChatBubbleProps {
  user?: Partial<Pick<UserObject, 'fullName' | 'profilePicture'>>;
}

export const AgentChatBubble = forwardRef<HTMLDivElement, AgentChatBubbleProps>(
  ({ user, ...props }, ref) => {
    return (
      <Flex
        ref={ref}
        alignItems="center"
        justifyContent="center"
        {...props}
        background="purpleGradient"
        border="1px solid"
        borderColor="whiteAlpha.200"
        borderRadius="40px 40px 40px 0px"
        boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.55)"
        cursor="pointer"
        height="32px"
        width="32px"
      >
        <UserAvatar size="sm" user={user} />
      </Flex>
    );
  }
);
