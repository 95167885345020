import { Flex } from '@chakra-ui/react';
import { Navigate } from 'react-router';
import { CONTAINER_PADDINGS } from '~/apps/consumer/components/Layouts/Container';
import { OrganizationHeading } from '~/apps/consumer/components/Organization/OrganizationHeading';
import { OrganizationListings } from '~/apps/consumer/components/OrganizationListings/OrganizationListings';
import { OrganizationMap } from '~/apps/consumer/components/OrganizationMap/OrganizationMap';
import { useAppContext } from '~/services/main/contexts/AppContext';

export const OrganizationOverviewPage = () => {
  const {
    selectedOrganization,
    isSelectedOrgAdmin,
    loadingIsSelectedOrgAdmin,
  } = useAppContext();

  if (
    selectedOrganization &&
    !isSelectedOrgAdmin &&
    !loadingIsSelectedOrgAdmin
  ) {
    return <Navigate replace to="/organizations" />;
  }

  return (
    <Flex
      direction="column"
      gap={4}
      width={{ base: '100%', md: '80%' }}
      {...CONTAINER_PADDINGS}
    >
      <Flex alignItems="center" justifyContent="space-between">
        <OrganizationHeading />
      </Flex>

      <Flex direction="column" gap={10}>
        <OrganizationMap />
        <OrganizationListings />
      </Flex>
    </Flex>
  );
};
