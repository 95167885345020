import {
  Card,
  CardBody,
  Heading,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FC, memo } from 'react';

interface TeamPerformancePanelProps {
  teamPerformance: Array<{ title?: string; value?: string | number | null }>;
}

export const TeamPerformancePanel: FC<TeamPerformancePanelProps> = memo(
  function TeamPerformancePanel({ teamPerformance }) {
    const hasValues = teamPerformance.some((performance) => performance.value);

    return (
      <VStack
        alignItems="stretch"
        flexGrow={1}
        justifyContent="stretch"
        spacing={4}
      >
        <Heading as="h2" fontSize="xl">
          Team Performance
        </Heading>

        <Stack
          alignItems="stretch"
          flexDir={{
            base: 'column',
            lg: 'row',
          }}
          flexGrow={1}
          justifyContent="space-evenly"
          spacing={3}
        >
          {!hasValues &&
            teamPerformance.map((performance) => (
              <Skeleton key={performance.title} height="188px" width="100%" />
            ))}
          {hasValues &&
            teamPerformance.map((performance) => (
              <Card key={performance.title} width="100%">
                <CardBody
                  alignItems="center"
                  as={VStack}
                  justifyContent="center"
                  spacing={0}
                  textAlign="center"
                >
                  <Heading
                    color="whiteAlpha.600"
                    fontWeight="500"
                    size="smallUppercase"
                  >
                    {performance.title}
                  </Heading>
                  <Text color="white" fontSize="5xl">
                    {performance.value}
                  </Text>
                </CardBody>
              </Card>
            ))}
        </Stack>
      </VStack>
    );
  }
);
