import {
  Box,
  Icon,
  IconButton,
  IconButtonProps,
  Tag,
  Text,
} from '@chakra-ui/react';

import { HStack } from '@chakra-ui/react';
import { AgentPerformanceFilters } from '@client/graphql/__generated__/types';
import { GeocodingFeature } from '@mapbox/search-js-core';
import { CalendarDays, X } from 'lucide-react';
import { FC, memo, useMemo } from 'react';
import { chakraStyles, Select } from '~/services/document/common/Select';
import { getIntervalOptions } from './Filters.constants';

interface FiltersProps {
  selectedZipCodes: string[];
  suggestions?: GeocodingFeature[];
  transactionFilters: Omit<AgentPerformanceFilters, 'zipCodes'>;
  setSuggestions?: React.Dispatch<React.SetStateAction<GeocodingFeature[]>>;
  setSelectedZipCodes: (zipCodes: string[]) => void;
  setTransactionFilters: (
    filters: Omit<AgentPerformanceFilters, 'zipCodes'>
  ) => void;
}

export const Filters = memo(function Filters({
  selectedZipCodes,
  suggestions,
  transactionFilters,
  setSelectedZipCodes,
  setSuggestions,
  setTransactionFilters,
}: FiltersProps) {
  const intervalOptions = useMemo(() => getIntervalOptions(), []);

  return (
    <HStack flexWrap="wrap" pt={4} px={3}>
      <HStack flexWrap="wrap">
        {suggestions?.map((suggestion) => (
          <Tag
            key={suggestion.id}
            borderRadius="sm"
            height="42px"
            pr={2}
            size="lg"
          >
            {suggestion.properties.context.place?.name}
            <RemoveButton
              onClick={() => {
                setSuggestions?.(
                  suggestions?.filter((s) => s.id !== suggestion.id)
                );
              }}
            />
          </Tag>
        ))}
        {selectedZipCodes.map((zipCode) => (
          <Tag key={zipCode} borderRadius="sm" height="42px" pr={2} size="lg">
            {zipCode}
            <RemoveButton
              onClick={() =>
                setSelectedZipCodes(
                  selectedZipCodes.filter((z) => z !== zipCode)
                )
              }
            />
          </Tag>
        ))}
      </HStack>

      <Box minWidth="100px">
        <Select
          chakraStyles={{
            ...chakraStyles,
            valueContainer(base) {
              return {
                ...base,
                display: 'block',
              };
            },
          }}
          components={{
            SingleValue: (props) => (
              <Box display="inline-block">
                <Icon
                  as={CalendarDays}
                  color="whiteAlpha.500"
                  display="inline-block"
                  mr={2}
                  verticalAlign="middle"
                />
                <Text display="inline-block">{props.data.label}</Text>
              </Box>
            ),
          }}
          isSearchable={false}
          menuPortalTarget={document.body}
          options={intervalOptions}
          size="sm"
          styles={{
            menuPortal: (base) => ({
              ...base,
              zIndex: 10,
            }),
          }}
          value={{
            label:
              intervalOptions.find(
                (option) => option.value === transactionFilters.intervalDays
              )?.label ?? '',
            value: transactionFilters.intervalDays,
          }}
          onChange={(option) =>
            setTransactionFilters({
              ...transactionFilters,
              intervalDays: option?.value || 0,
            })
          }
        />
      </Box>
    </HStack>
  );
});

const RemoveButton: FC<Omit<IconButtonProps, 'aria-label'>> = (props) => {
  return (
    <IconButton
      as={X}
      borderRadius="sm"
      color="whiteAlpha.500"
      colorScheme="gray"
      cursor="pointer"
      height="22px"
      minWidth="auto"
      px={1}
      size="xs"
      variant="ghost"
      width="22px"
      {...props}
      aria-label="Remove zip code"
    />
  );
};
