import { Heading, HeadingProps } from '@chakra-ui/react';
import { FC } from 'react';
import { theme } from '~/common/theme';

export const ComingSoon: FC<HeadingProps> = (props) => {
  return (
    <Heading
      bgClip="text"
      bgGradient={theme.colors.purpleGradient}
      size="smallUppercase"
      {...props}
    >
      Coming soon
    </Heading>
  );
};
