import { useAllMlSesQuery } from '@client/graphql/__generated__/main-operations';
import { MlsObjectForAllMlSesFragment } from '@client/graphql/__generated__/types';
import { Select, SelectProps } from '@document/common/Select';
import * as Sentry from '@sentry/react';
import { FC, useEffect, useMemo } from 'react';
import {
  FeatureKey,
  useGetStateAndMLSFeatureFlagPayload,
} from '~/apps/consumer/hooks/useIsFeatureEnabledForStateOrMls';

export interface MLSSelectOption {
  label: string;
  value: string;
  mlsOSN: string;
}

/**
 * @param selectedMlsId - The MLS ID that is currently selected.
 * @param featureKeyFilter - Only show MLS options that are enabled for the given feature flag key.
 */
interface MLSSelectProps extends SelectProps<MLSSelectOption, false> {
  featureKeyFilter: FeatureKey;
  selectedMlsId?: string;
}

const mlsToOption = (mls: MlsObjectForAllMlSesFragment): MLSSelectOption => ({
  label: `${mls.name} (${mls.originatingSystemName})`,
  value: mls.id,
  mlsOSN: mls.originatingSystemName ?? '',
});

const sortMlsByName = (
  a: MlsObjectForAllMlSesFragment,
  b: MlsObjectForAllMlSesFragment
): number => a.name.localeCompare(b.name);

export const MLSSelect: FC<MLSSelectProps> = ({
  selectedMlsId,
  featureKeyFilter,
  onChange,
  ...selectProps
}) => {
  const { data, loading } = useAllMlSesQuery();
  const { mls: availableMlsOSNs } =
    useGetStateAndMLSFeatureFlagPayload(featureKeyFilter) ?? {};

  // Memoize options to prevent unnecessary recalculations
  const options = useMemo(() => {
    if (!data?.allMLSes?.mls) return [];

    if (!availableMlsOSNs) {
      Sentry.captureMessage(
        '[MLSSelect] No available MLS OSNs for feature flag',
        {
          extra: {
            featureKeyFilter,
          },
        }
      );
    }

    return data.allMLSes.mls
      .filter(
        (mls) =>
          mls.originatingSystemName &&
          (window.ENVS.environment === 'e2e' ||
            (availableMlsOSNs ?? []).includes(mls.originatingSystemName))
      )
      .sort(sortMlsByName)
      .map(mlsToOption);
  }, [data?.allMLSes.mls, availableMlsOSNs, featureKeyFilter]);

  const value = useMemo(
    () => options.find((mlsOption) => mlsOption.value === selectedMlsId),
    [selectedMlsId, options]
  );

  // Handle default selection when no selectedMlsId is provided
  useEffect(() => {
    if (!selectedMlsId && options.length > 0) {
      (onChange as (value: MLSSelectOption) => void)?.(options[0]);
    }
  }, [selectedMlsId, options, onChange]);

  return (
    <Select
      isClearable
      isDisabled={options.length <= 1}
      isLoading={loading}
      menuPortalTarget={document.body}
      options={options}
      placeholder="Please select"
      size="md"
      styles={{
        menuPortal: (base) => ({
          ...base,
          zIndex: 9999,
        }),
      }}
      value={value}
      onChange={onChange}
      {...selectProps}
    />
  );
};
