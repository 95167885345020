import isNil from 'lodash/isNil';
import { FC, memo, useMemo } from 'react';
import { ValidationTag } from './ValidationTag';

interface PercentageTagProps {
  value: number;
  compareValue?: number;
  size?: string;
  isNegative?: boolean;
}

export const PercentageTag: FC<PercentageTagProps> = memo(
  function PercentageTag({ value, compareValue, size, isNegative }) {
    const diffPercentage = useMemo(() => {
      if (compareValue && !isNil(value)) {
        return Math.round((value / compareValue - 1) * 100);
      }

      return 0;
    }, [value, compareValue]);

    return (
      <ValidationTag
        isError={isNegative ? diffPercentage > 0 : diffPercentage < 0}
        isSuccess={isNegative ? diffPercentage < 0 : diffPercentage > 0}
        size={size}
      >
        {diffPercentage < 0 ? '-' : '+'}
        {Math.abs(diffPercentage)}%
      </ValidationTag>
    );
  }
);
