import {
  GetBuyerOfferAnalysisDocument,
  useCalculateOfferScoreMutation,
  useGetOfferRecsLazyQuery,
  useUpdateBuyerOfferAnalysisMutation,
} from '@client/graphql/__generated__/main-operations';
import {
  BuyerOfferAnalysisFragment,
  ListingFragment,
  OfferScoreInput,
  UpdateBuyerOfferAnalysisInput,
} from '@client/graphql/__generated__/types';
import * as Sentry from '@sentry/react';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  AgentContingencyMap,
  AgentKeyTermMap,
  getTitle,
  mapAgentOfferRecs,
  transformAgentOfferRecs,
} from '../utils';

export const useUpdateBuyerOfferAnalysis = ({
  buyerOfferAnalysis,
  listing,
}: {
  buyerOfferAnalysis?: BuyerOfferAnalysisFragment;
  listing?: ListingFragment;
}) => {
  const [isChangesSaved, setIsChangesSaved] = useState(true);
  const [isCalculatingOfferScore, setIsCalculatingOfferScore] = useState(false);
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [agentNotes, setAgentNotes] = useState<string | undefined>(undefined);
  const [agentContingencies, setAgentContingencies] =
    useState<AgentContingencyMap>({});
  const [agentKeyTerms, setAgentKeyTerms] = useState<AgentKeyTermMap>({});

  const [getOfferRecs, { loading: offerRecsLoading }] =
    useGetOfferRecsLazyQuery({
      variables: {
        input: {
          buyerOfferAnalysisId: buyerOfferAnalysis?.id || '',
        },
      },
    });

  const [updateBuyerOfferAnalysis] = useUpdateBuyerOfferAnalysisMutation({
    refetchQueries: [
      {
        query: GetBuyerOfferAnalysisDocument,
        variables: { uuid: buyerOfferAnalysis?.id || '' },
      },
    ],
  });

  const [calculateOfferScore] = useCalculateOfferScoreMutation();

  /**
   * This callback is used to update the indigo offer recs stored on the buyer offer analysis.
   */
  const updateIndigoOfferRecs = useCallback(async () => {
    setIsChangesSaved(false);
    const { data } = await getOfferRecs();

    const offerRecs = data?.getOfferRecs;

    if (!offerRecs) {
      Sentry.captureMessage('No offer recs data returned', {
        extra: {
          buyerOfferAnalysisId: buyerOfferAnalysis?.id ?? 'unknown',
        },
        level: 'warning',
      });
      setIsChangesSaved(true);

      return;
    }

    await updateBuyerOfferAnalysis({
      variables: {
        input: {
          indigoOfferRecs:
            offerRecs as unknown as UpdateBuyerOfferAnalysisInput['indigoOfferRecs'],
          buyerOfferAnalysisId: buyerOfferAnalysis?.id || '',
        },
      },
    });
    setIsChangesSaved(true);
  }, [buyerOfferAnalysis, getOfferRecs, updateBuyerOfferAnalysis]);

  const updateTitle = useCallback(
    (newTitle?: string) => {
      if (newTitle !== buyerOfferAnalysis?.title) {
        setIsChangesSaved(false);
        const formattedTitle = getTitle(newTitle, listing?.mlsListing);
        void (async () => {
          await updateBuyerOfferAnalysis({
            variables: {
              input: {
                title: formattedTitle,
                buyerOfferAnalysisId: buyerOfferAnalysis?.id || '',
              },
            },
          });
          setTitle(formattedTitle);
          setIsChangesSaved(true);
        })();
      }
    },
    [
      updateBuyerOfferAnalysis,
      buyerOfferAnalysis?.id,
      buyerOfferAnalysis?.title,
      listing,
    ]
  );

  const updateAgentNotes = useCallback(
    (newNotes?: string) => {
      if (newNotes !== buyerOfferAnalysis?.agentNotes) {
        setIsChangesSaved(false);
        void (async () => {
          await updateBuyerOfferAnalysis({
            variables: {
              input: {
                agentNotes: newNotes || (null as unknown as undefined),
                buyerOfferAnalysisId: buyerOfferAnalysis?.id || '',
              },
            },
          });
          setIsChangesSaved(true);
        })();
      }
    },
    [
      updateBuyerOfferAnalysis,
      buyerOfferAnalysis?.id,
      buyerOfferAnalysis?.agentNotes,
    ]
  );

  const updateAgentOfferRecs = useCallback(
    async (
      newAgentContingencies: AgentContingencyMap,
      newAgentKeyTerms: AgentKeyTermMap,
      buyerOfferAnalysisId: string
    ) => {
      setIsChangesSaved(false);
      setIsCalculatingOfferScore(true);
      const agentOfferRecs = transformAgentOfferRecs(
        newAgentContingencies,
        newAgentKeyTerms
      );

      await updateBuyerOfferAnalysis({
        variables: {
          input: {
            agentOfferRecs: agentOfferRecs
              ? (agentOfferRecs as unknown as UpdateBuyerOfferAnalysisInput['agentOfferRecs'])
              : undefined,
            buyerOfferAnalysisId: buyerOfferAnalysisId,
          },
        },
        refetchQueries: [],
      });
      const { data } = await calculateOfferScore({
        variables: {
          buyerOfferAnalysisId: buyerOfferAnalysisId,
        },
      });
      const offerScore = data?.calculateOfferScore;

      await updateBuyerOfferAnalysis({
        variables: {
          input: {
            offerScore: offerScore as OfferScoreInput,
            buyerOfferAnalysisId: buyerOfferAnalysisId,
          },
        },
      });
      setIsChangesSaved(true);
      setIsCalculatingOfferScore(false);
    },
    [updateBuyerOfferAnalysis, calculateOfferScore]
  );

  const debouncedUpdateAgentOfferRecs = useRef(
    // Save once there are no changes for 3 seconds as an autosave
    debounce(updateAgentOfferRecs, 1000)
  ).current;

  useEffect(() => {
    if (buyerOfferAnalysis?.id && !buyerOfferAnalysis.indigoOfferRecs) {
      void (async () => {
        await updateIndigoOfferRecs();
      })();
    }
  }, [buyerOfferAnalysis, updateIndigoOfferRecs]);

  useEffect(() => {
    const newTitle = getTitle(buyerOfferAnalysis?.title, listing?.mlsListing);

    setTitle(newTitle);
  }, [buyerOfferAnalysis?.title, listing]);

  useEffect(() => {
    setAgentNotes(buyerOfferAnalysis?.agentNotes);
  }, [buyerOfferAnalysis?.agentNotes]);

  const originalMappedAgentOfferRecs = useMemo(() => {
    return mapAgentOfferRecs(buyerOfferAnalysis);
  }, [buyerOfferAnalysis]);

  // Initialize agentContingencies and agentKeyTerms from BOA agentOfferRecs
  useEffect(() => {
    const { contingencies, keyTerms } = originalMappedAgentOfferRecs;

    if (contingencies || keyTerms) {
      setAgentContingencies(contingencies);
      setAgentKeyTerms(keyTerms);
    }
  }, [originalMappedAgentOfferRecs]);

  // Trigger debounced autosave when agentContingencies or agentKeyTerms change
  useEffect(() => {
    const hasSomeInput =
      !isEmpty(agentContingencies) || !isEmpty(agentKeyTerms);

    const { contingencies: originalContingencies, keyTerms: originalKeyTerms } =
      originalMappedAgentOfferRecs;
    const hasSomeChanges =
      !isEqual(agentContingencies, originalContingencies) ||
      !isEqual(agentKeyTerms, originalKeyTerms);

    if (hasSomeInput && hasSomeChanges && buyerOfferAnalysis?.id) {
      setIsChangesSaved(false);
      void debouncedUpdateAgentOfferRecs(
        agentContingencies,
        agentKeyTerms,
        buyerOfferAnalysis.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    agentContingencies,
    agentKeyTerms,
    debouncedUpdateAgentOfferRecs,
    buyerOfferAnalysis?.id,
  ]);

  return {
    title,
    updateTitle,
    updateAgentNotes,
    agentNotes,
    setAgentNotes,
    setTitle,
    updateIndigoOfferRecs,
    agentContingencies,
    agentKeyTerms,
    setAgentContingencies,
    setAgentKeyTerms,
    isChangesSaved,
    offerRecsLoading,
    isCalculatingOfferScore,
  };
};
