import { Flex, Heading, Skeleton, Text, VStack } from '@chakra-ui/react';
import { getPartyFinancingTypeLabel } from '~/apps/consumer/utils/party.utils';
import { formatPercent } from '~/common/utils/formatter';
import { formatToCurrency } from '~/services/document/utils/number';
import { UserAvatar } from '../../User/UserAvatar';
import { useBuyerOfferAnalysisContext } from '../Context/BuyerOfferAnalysisContext';

export const BuyerInfo = () => {
  const { buyerOfferAnalysis } = useBuyerOfferAnalysisContext();

  const party = buyerOfferAnalysis?.party;
  const buyer = party?.primaryConsumer;

  const financingType = getPartyFinancingTypeLabel(party);
  const downPayment = formatPercent(
    party?.partyFinancing?.percentDownPayment,
    3
  );
  const cashReserves = formatToCurrency(party?.partyFinancing?.cashReserves, 0);

  let info = (
    <Flex direction="column" gap={4} height="250px" p={5} width="100%">
      <Flex alignItems="center" justifyContent="space-between" width="100%">
        <Heading color="whiteAlpha.600" fontSize="sm" size="smallUppercase">
          Buyer
        </Heading>
        {/* TODO: Add back in once we handle edting buyer on buyer offer analysis page */}
        {/* <Icon as={PencilIcon} cursor="pointer" /> */}
      </Flex>
      <VStack alignItems="center">
        <UserAvatar
          size="lg"
          user={{
            fullName: buyer?.isEntity ? buyer?.entityName : buyer?.fullName,
            profilePicture: buyer?.profilePicture,
          }}
        />
        <VStack spacing={0}>
          <Text fontWeight="medium" size="lg">
            {buyer?.isEntity ? buyer?.entityName : buyer?.fullName}
          </Text>
          {financingType && <Text color="whiteAlpha.700">{financingType}</Text>}
          {downPayment && (
            <Text color="whiteAlpha.700">{downPayment} down</Text>
          )}
          {cashReserves && (
            <Text color="whiteAlpha.700">{cashReserves} reserves</Text>
          )}
        </VStack>
      </VStack>
    </Flex>
  );

  if (!party || !buyer) {
    info = <Skeleton>{info}</Skeleton>;
  }

  return info;
};
