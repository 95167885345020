import { useDisclosure } from '@chakra-ui/react';
import {
  useListingComparableSetForBuyerOfferAnalysisQuery,
  useUpdateListingComparableSetForBuyerOfferAnalysisMutation,
} from '@client/graphql/__generated__/main-operations';
import {
  BuyerOfferAnalysisFragment,
  UpdateListingComparableInput,
} from '@client/graphql/__generated__/types';
import isEqualWith from 'lodash/isEqualWith';
import { useEffect, useMemo, useState } from 'react';
import { ComparableState } from '~/apps/consumer/pages/ListingSetup/Comparables/ComparablesPage';

export const useBuyerOfferAnalysisComps = ({
  updateIndigoOfferRecs,
  buyerOfferAnalysis,
}: {
  updateIndigoOfferRecs: () => Promise<void>;
  buyerOfferAnalysis?: BuyerOfferAnalysisFragment;
}) => {
  const [initialSelectedComp, setInitialSelectedComp] =
    useState<ComparableState>();
  const [comps, setComps] = useState<ComparableState[]>([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isCompsUpdated, setIsCompsUpdated] = useState(false);
  const [updateComparableSet] =
    useUpdateListingComparableSetForBuyerOfferAnalysisMutation();

  const {
    data: comparableSetData,
    loading: comparableSetLoading,
    refetch: refetchComparableSet,
  } = useListingComparableSetForBuyerOfferAnalysisQuery({
    variables: {
      input: {
        buyerOfferAnalysisId: buyerOfferAnalysis?.id || '',
      },
    },
    skip: !buyerOfferAnalysis,
  });

  const { isOpen: isEditCompsModalOpen, onOpen, onClose } = useDisclosure();

  const updateComps = async (comparables: UpdateListingComparableInput[]) => {
    await updateComparableSet({
      variables: {
        input: {
          comparables,
          buyerOfferAnalysisId: buyerOfferAnalysis?.id || '',
        },
      },
    });
    await refetchComparableSet();
  };

  const onEditCompsModalOpen = (comp?: ComparableState) => {
    comp && setInitialSelectedComp(comp);
    onOpen();
  };

  const onEditCompsModalClose = () => {
    if (isCompsUpdated) {
      void updateIndigoOfferRecs();
      setIsCompsUpdated(false);
    }
    onClose();
  };

  const originalOrderedComps = useMemo(() => {
    if (
      comparableSetData?.listingComparableSetForBuyerOfferAnalysis
        ?.listingComparables
    ) {
      return comparableSetData?.listingComparableSetForBuyerOfferAnalysis?.listingComparables
        ?.toSorted((a, b) => a.order - b.order)
        ?.map((comp) => ({
          mlsListing: comp.mlsListing,
        }));
    }

    return [];
  }, [comparableSetData]);

  // sets the comps on initial page load
  useEffect(() => {
    if (!isDirty && originalOrderedComps?.length) {
      setComps(originalOrderedComps || []);
    }
  }, [isDirty, originalOrderedComps]);

  // updates the dirty state based on changes to the comps compared to the original ordered comps
  useEffect(() => {
    const comparator = (a: ComparableState[], b: ComparableState[]) =>
      a.length === b.length &&
      a.every(
        (comparable, index) =>
          comparable.mlsListing.id === b[index].mlsListing.id
      );
    if (!isEqualWith(comps, originalOrderedComps, comparator)) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  }, [comps]); // eslint-disable-line react-hooks/exhaustive-deps

  // updates the comps in the database when the comps are dirty
  useEffect(() => {
    if (isDirty) {
      const input = comps.map((comp, index) => ({
        mlsListingId: comp.mlsListing.id,
        order: index + 1,
      }));
      void updateComps(input);
      setIsCompsUpdated(true);
    }
  }, [isDirty, comps]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    comps,
    setComps,
    isDirty,
    setIsDirty,
    isCompsUpdated,
    setIsCompsUpdated,
    updateComps,
    onEditCompsModalOpen,
    onEditCompsModalClose,
    isEditCompsModalOpen,
    comparableSetLoading,
    refetchComparableSet,
    initialSelectedComp,
    setInitialSelectedComp,
    comparableSet: comparableSetData?.listingComparableSetForBuyerOfferAnalysis,
  };
};
