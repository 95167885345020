import { Flex, Spinner, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router';
import { useAppContext } from '~/services/main/contexts/AppContext';
import { EmbeddedDashboardUrlResponse } from './types';

export const OrganizationOfferBenchmarksPage = () => {
  const {
    selectedOrganization,
    isSelectedOrgAdmin,
    loadingIsSelectedOrgAdmin,
  } = useAppContext();

  const [dashboardUrl, setDashboardUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchDashboardUrl = async () => {
      try {
        const url = new URL(
          '/embedded-dashboard/generateUrl',
          window.location.origin
        );
        if (selectedOrganization?.id) {
          url.searchParams.set('org', selectedOrganization.id);
        }

        const response = await fetch(url.toString());
        if (!response.ok) {
          throw new Error('Failed to fetch dashboard URL');
        }
        const data = (await response.json()) as EmbeddedDashboardUrlResponse;
        setDashboardUrl(data.url);
      } catch (err) {
        setError('Failed to load dashboard');
      }
    };

    void fetchDashboardUrl();
  }, [selectedOrganization?.id]);

  if (
    selectedOrganization &&
    !isSelectedOrgAdmin &&
    !loadingIsSelectedOrgAdmin
  ) {
    return <Navigate replace to="/organizations" />;
  }

  return (
    <Flex direction="column" height="100vh" width="100%">
      {error ? (
        <Text color="red.500" p={4}>
          {error}
        </Text>
      ) : !dashboardUrl ? (
        <Flex align="center" flex={1} justify="center">
          <Spinner size="xl" />
        </Flex>
      ) : (
        <iframe
          allowTransparency
          src={dashboardUrl}
          style={{
            width: '100%',
            height: '100%',
            border: 'none',
          }}
        />
      )}
    </Flex>
  );
};
