import { useOfferMetricsQuery } from '@client/graphql/__generated__/main-operations';
import { CopilotActions } from '@common/enums';
import { useCopilotAction } from '@copilotkit/react-core';
import { useEffect } from 'react';
import { OfferMetricsGraph } from '~/apps/consumer/components/CopilotChat/Tools/OfferMetricsGraph';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useIsChatEnabled } from '~/common/copilot/hooks/isChatEnabled';
import { insertJson, runQuery } from '~/common/utils/duckdb';
import { objectToCsv } from '~/common/utils/formatter';

export const useMarketDataGraphqlAction = () => {
  const isChatEnabled = useIsChatEnabled();
  const { data: offerMetricsData } = useOfferMetricsQuery({
    skip: !isChatEnabled,
  });

  useEffect(() => {
    if (offerMetricsData?.offerMetrics) {
      void insertJson('offer_metrics', offerMetricsData.offerMetrics);
    }
  }, [offerMetricsData]);

  useCopilotAction({
    name: CopilotActions.graphForKeyTermTrend,
    description: [
      'Use this tool to generate and display a trend graph for offer key term metrics such as due diligence fees,',
      'earnest money deposits, and bid-ask spreads. The outcome should be a clear, visually informative graph that',
      'effectively conveys trends over time. Ensure accuracy and relevance in the data presented.',
    ].join(' '),
    parameters: [
      {
        name: 'sql_query',
        type: 'string',
        description: 'The SQL query to run',
        required: true,
      },
    ],
    available: isChatEnabled ? 'enabled' : 'disabled',
    handler: async (props) => {
      const result = await runQuery(props.sql_query);

      return [
        'Give a short analysis of the data in the context of a graph.',
        'The result of the query is:',
        objectToCsv(result),
      ].join('\n');
    },
    render: (props) => {
      if (props.status !== 'complete') {
        return <CenterSpinner />;
      }

      return <OfferMetricsGraph sql_query={props.args.sql_query} />;
    },
  });

  return {
    offerMetricsData,
  };
};
