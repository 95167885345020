import {
  ListingComparableSetFragment,
  MlsListingFragment,
} from '@client/graphql/__generated__/types';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  closestCenter,
  useSensor,
} from '@dnd-kit/core';
import {
  SortableContext,
  arrayMove,
  horizontalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { useDndKitDefaults } from '~/common/hooks/useDndKitDefaults';
import { ComparableState } from '../../../pages/ListingSetup/Comparables/ComparablesPage';
import { ComparableColumn } from './ComparableColumns/ComparableColumn';
import { SortableColumn } from './ComparableColumns/SortableColumn';

interface DraggableComparableColumnsProps {
  comparableSet?: ListingComparableSetFragment;
  comparables?: ComparableState[];
  setComparables?: React.Dispatch<React.SetStateAction<ComparableState[]>>;
  isEditMode?: boolean;
  mainMlsListing?: MlsListingFragment;
  showAdjustments?: boolean;
  showNotes?: boolean;
  onAdjustmentsUpdate?: () => Promise<void> | void;
}

export const DraggableComparableColumns = ({
  comparableSet,
  comparables,
  setComparables,
  isEditMode,
  mainMlsListing,
  showAdjustments = false,
  showNotes = false,
  onAdjustmentsUpdate,
}: DraggableComparableColumnsProps) => {
  const {
    sensors,
    dropAnimationConfig,
    activeDraggingItemData,
    setActiveDraggingItemData,
  } = useDndKitDefaults<ComparableState>({
    extendSensors: [
      useSensor(KeyboardSensor, {
        coordinateGetter: sortableKeyboardCoordinates,
      }),
    ],
  });

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event;
    const activeComparable = comparables?.find(
      (comp) => comp.mlsListing.id === active.id
    );
    setActiveDraggingItemData(activeComparable as ComparableState);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (over?.id && active.id !== over.id) {
      setComparables &&
        setComparables((items) => {
          const oldIndex = items.findIndex(
            (item) => item.mlsListing.id === active.id
          );
          const newIndex = items.findIndex(
            (item) => item.mlsListing.id === over.id
          );

          return arrayMove(items, oldIndex, newIndex);
        });
    }
    setActiveDraggingItemData(null);
  };

  const comparablesList = (
    <>
      {comparables?.map((comp) => (
        <SortableColumn
          key={comp.mlsListing.id}
          comparableSet={comparableSet}
          isEditMode={isEditMode}
          mainMlsListing={mainMlsListing}
          mlsListing={comp.mlsListing}
          showAdjustments={showAdjustments}
          showNotes={showNotes}
          onAdjustmentsUpdate={onAdjustmentsUpdate}
          onDelete={(mlsListing) => {
            const index = comparables.findIndex(
              (comp) => comp.mlsListing.id === mlsListing.id
            );
            setComparables &&
              setComparables((prevState) => {
                return prevState.toSpliced(index, 1);
              });
          }}
        />
      ))}
    </>
  );

  if (!isEditMode) {
    return comparablesList;
  }

  return (
    <DndContext
      collisionDetection={closestCenter}
      sensors={sensors}
      onDragEnd={handleDragEnd}
      onDragStart={handleDragStart}
    >
      <SortableContext
        items={comparables?.map((comp) => comp.mlsListing.id) || []}
        strategy={horizontalListSortingStrategy}
      >
        {comparablesList}
      </SortableContext>
      <DragOverlay dropAnimation={dropAnimationConfig}>
        {activeDraggingItemData ? (
          <ComparableColumn
            comparableSet={comparableSet}
            isEditMode={isEditMode}
            mainMlsListing={mainMlsListing}
            mlsListing={activeDraggingItemData.mlsListing}
            showAdjustments={showAdjustments}
            showNotes={showNotes}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};
