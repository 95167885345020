import {
  Card,
  CardBody,
  CardHeader,
  Flex,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { useBuyerOfferAnalysisContext } from '../Context/BuyerOfferAnalysisContext';
import { ContingenciesRow } from './ContingenciesRow';

export const ContingenciesTable = () => {
  const {
    offerRecs,
    offerRecsLoading,
    agentContingencies,
    setAgentContingencies,
    buyerOfferAnalysis,
  } = useBuyerOfferAnalysisContext();
  const contingencies = offerRecs?.contingencies;
  const buyersAgentUser = buyerOfferAnalysis?.buyersAgent?.user;

  if (!contingencies || offerRecsLoading) {
    return <Skeleton height="300px" width="100%" />;
  }

  return (
    <Card width="100%">
      <CardHeader
        borderBottom="1px solid"
        borderBottomColor="whiteAlpha.200"
        pb={4}
      >
        <Flex alignItems="center" gap={6} justifyContent="space-between">
          <Flex basis={0} flexGrow={1}>
            <Text color="whiteAlpha.600">Contingencies</Text>
          </Flex>
          <Flex basis={0} flexGrow={2}>
            <Text color="whiteAlpha.600">Market Data</Text>
          </Flex>
          <Flex basis={0} flexGrow={2}>
            <Text color="whiteAlpha.600">Your Recommendations</Text>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody p={0}>
        {contingencies?.map((contingency) => {
          const contingencyValue = agentContingencies[contingency.type]
            ?.value as boolean | undefined;

          const contingencyNotes = agentContingencies[contingency.type]?.notes;

          const onUpdateValue = (value: boolean) => {
            setAgentContingencies({
              ...agentContingencies,
              [contingency.type]: {
                ...agentContingencies[contingency.type],
                value,
              },
            });
          };

          const onUpdateNotes = (notes: string) => {
            setAgentContingencies({
              ...agentContingencies,
              [contingency.type]: {
                ...agentContingencies[contingency.type],
                notes,
              },
            });
          };

          if (contingency.__typename === 'ContingencyError') {
            return (
              <ContingenciesRow
                key={contingency.type}
                buyersAgentUser={buyersAgentUser}
                errorMessage={contingency.reason}
                label={contingency.type?.split('_').join(' ').toLowerCase()}
                notes={contingencyNotes}
                value={contingencyValue}
                onUpdateNotes={onUpdateNotes}
                onUpdateValue={onUpdateValue}
              />
            );
          }

          const confidence =
            contingency.context?.source.__typename === 'IndigoOffersSource'
              ? contingency.context?.source?.metadata?.confidence
              : undefined;

          const distanceMiles =
            contingency.context?.source.__typename === 'IndigoOffersSource'
              ? contingency.context?.source?.metadata?.distanceMiles
              : undefined;

          const timePeriodMonths =
            contingency.context?.source.__typename === 'IndigoOffersSource'
              ? contingency.context?.source?.metadata?.timePeriodMonths
              : undefined;

          return (
            <ContingenciesRow
              key={contingency.type}
              buyersAgentUser={buyersAgentUser}
              confidence={confidence}
              distanceMiles={distanceMiles}
              label={contingency.type?.split('_').join(' ').toLowerCase()}
              marketData={contingency.context?.marketData}
              notes={contingencyNotes}
              source={contingency.context?.source?.type}
              timePeriodMonths={timePeriodMonths}
              value={contingencyValue}
              onUpdateNotes={onUpdateNotes}
              onUpdateValue={onUpdateValue}
            />
          );
        })}
      </CardBody>
    </Card>
  );
};
