import dayjs from 'dayjs';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { CenterSpinner } from '~/common/components/CenterSpinner';
import { useDuckDBQuery } from '~/common/hooks/useDuckDbQuery';
import { colors } from '~/common/theme';
import { formatPercent } from '~/common/utils/formatter';
import { enumToLabel } from '~/services/document/utils/enums';

export const OfferMetricsGraph = (props: { sql_query: string }) => {
  const { data, loading, error } = useDuckDBQuery(props.sql_query);

  if (loading) {
    return <CenterSpinner />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (data.length === 0) {
    return <div>No data</div>;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
  const numberColumns = Object.entries(data[0])
    .filter(
      ([key, value]: [string, number]) =>
        typeof value === 'number' && !key.includes('date')
    )
    .map(([key]: [string, number]) => key);

  return (
    <ResponsiveContainer
      height={250}
      style={{
        backgroundColor: 'white',
        padding: '10px 10px 10px 0px',
        borderRadius: '6px',
        margin: '15px 0',
      }}
      width="100%"
    >
      <LineChart data={data} style={{ fontSize: '12px' }}>
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
        <YAxis
          label={{
            value: 'Percentage',
            angle: -90,
            position: 'insideLeft',
          }}
          tickFormatter={(value) => {
            return formatPercent(value as number) as string;
          }}
        />
        <XAxis
          dataKey="date_interval"
          tickFormatter={(value) => {
            return dayjs(value as string).format('MMM');
          }}
        />
        <Tooltip
          formatter={(value, name) => {
            return [
              formatPercent(value as number) as string,
              enumToLabel(name as string),
            ];
          }}
        />
        {numberColumns.map((colName: string) => (
          <Line
            key={colName}
            dataKey={colName}
            label={enumToLabel(colName)}
            stroke={
              colName.includes('not_accepted')
                ? colors.red[300]
                : colors.green[600]
            }
            type="monotone"
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};
