import {
  ActivityObject,
  ActivityType,
  MlsListingStatus,
  PreferenceValueType,
  PublicListingComparableFragment,
  PublicListingDocumentUploadFragment,
  PublicPreferenceValueFragment,
  UsState,
} from '@client/graphql/__generated__/types';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

export const fakeListingActivities = (): ActivityObject[] => {
  const now = dayjs();

  return [
    {
      id: '1',
      type: ActivityType.ANNOUNCEMENT,
      createdAt: now.subtract(1, 'day').toDate(),
      metadata: {
        [ActivityType.ANNOUNCEMENT]: { message: 'New price reduction!' },
      },
    },
    {
      id: '2',
      type: ActivityType.DOCUMENT_UPDATE,
      createdAt: now.subtract(2, 'day').toDate(),
      metadata: {
        [ActivityType.DOCUMENT_UPDATE]: {
          customDocumentName: 'Special instructions for submitting offer',
        },
      },
    },
    {
      id: '3',
      type: ActivityType.ANNOUNCEMENT,
      createdAt: now.subtract(3, 'day').toDate(),
      metadata: {
        [ActivityType.ANNOUNCEMENT]: { message: 'Open house scheduled' },
      },
    },
    {
      id: '4',
      type: ActivityType.SYSTEM_UPDATE,
      createdAt: now.subtract(4, 'day').toDate(),
      metadata: {
        [ActivityType.SYSTEM_UPDATE]: {
          updatedField: 'List price',
          previousValue: '$200,000',
          nextValue: '$250,000',
        },
      },
    },
  ];
};

export const fakeComparable = (): PublicListingComparableFragment => {
  return {
    id: uuidv4(),
    mlsListing: {
      id: uuidv4(),
      mlsId: 205344765,
      listingId: '4098103',
      listDate: '2024-01-05',
      listPrice: 674900,
      originalListPrice: 674900,
      terms: 'Cash, Conventional, VA Loan',
      mlsName: '',
      photos: [
        'https://s3-us-west-2.amazonaws.com/cdn.simplyrets.com/properties/cml/photos/CAR119237951/1.jpg',
      ],
      remarks:
        'LOOKS LIKE A MODEL! - Upgrades galore!!Gourmet kitchen with SS appliances with an upscale refrigerator that also has a coffee maker - 5 burner gas cooktop - Beautiful cabinets - large breakfast bar opening up to a beautiful family room with gas fireplace -office/study is a great flex space - primary bedroom on main with walk-in closet with custom closet organizers and luxury bath with walk-in shower - enclosed porch off primary with glass sliding barn doors - beautiful outdoor private retreat enclosed patio perfect for enjoying Carolina evenings or morning coffee -2nd bedroom with bath on main - Upstairs there is a guest bedroom/bath/study area and a large bonus room-Tons of upgrades in this home - Garage has electric car charger - All this PLUS a  5 x 10 storage room off the patio - Yard Maintenance included -Convenient to shopping/downtown Matthews\r\nThe Owner has added blinds, shades, fans, custom closets, laundry room  cabinets and courtyard improvement -\r\nYou will LOVE this one!!!',
      modified: '2024-04-02T18:11:05.626Z',
      address: {
        city: 'Stallings',
        country: 'United States',
        full: '4030 Pleasant Run Drive',
        postalCode: '28104',
        state: 'NC',
        streetName: 'Pleasant Run Drive',
        streetNumber: 4030,
        streetNumberText: '4030',
        unit: '',
        __typename: 'MLSListingAddressObject',
      },
      addressFull: '4030 Pleasant Run Drive Stallings, NC',
      property: {
        roof: 'Shingle',
        cooling: 'Central Air',
        style: 'Transitional',
        area: 2749,
        bathsFull: 3,
        bathsHalf: 0,
        flooring: 'Carpet, Hardwood, Tile',
        heating: 'Central, Natural Gas',
        bathrooms: '3',
        foundation: 'Slab',
        laundryFeatures: 'Laundry Room, Main Level',
        lotDescription: 'Corner Lot',
        subType: 'SingleFamilyResidence',
        bedrooms: 3,
        interiorFeatures:
          'Breakfast Bar, Built-in Features, Open Floorplan, Pantry, Split Bedroom, Tray Ceiling(s), Walk-In Closet(s)',
        exteriorFeatures: 'In-Ground Irrigation, Lawn Maintenance, Storage',
        lotSizeArea: 0.15,
        lotSizeAreaUnits: 'Acres',
        subdivision: 'The Courtyards on Lawyers Road',
        construction: 'Fiber Cement, Shingle/Shake, Stone',
        garageSpaces: 2,
        yearBuilt: 2022,
        subTypeText: 'Single Family Residence',
        __typename: 'MLSListingPropertyObject',
      },
      office: {
        brokerid: '130305',
        name: 'RE/MAX Executive',
        servingName: 'RE/MAX Executive',
        contact: {
          office: '704-405-8800',
          __typename: 'MLSListingContactObject',
        },
        __typename: 'MLSListingOfficeObject',
      },
      agent: {
        id: '62002',
        firstName: 'Sylvia',
        lastName: 'Hefferon',
        contact: {
          office: '704-405-8800',
          cell: '704-564-3458',
          __typename: 'MLSListingContactObject',
        },
        __typename: 'MLSListingAgentObject',
      },
      mls: {
        statusText: 'Closed',
        status: MlsListingStatus.Closed,
        daysOnMarket: 23,
        originatingSystemName: 'CanopyMLS',
        __typename: 'MLSListingMLSObject',
      },
      association: {
        fee: 275,
        frequency: 'Monthly',
        name: 'key Property Management',
        __typename: 'MLSListingAssociationObject',
      },
      compliance: {
        buyerAgencyCompensation: 3,
        buyerAgencyCompensationType: '%',
        subAgencyCompensation: 0,
        subAgencyCompensationType: '%',
        attributionContact: 'sylvia@sylviahefferon.com',
        __typename: 'MLSListingComplianceObject',
      },
      sales: {
        closeDate: '2024-04-02T04:00:00Z',
        closePrice: 665000,
        __typename: 'MLSListingSalesObject',
      },
      geo: {
        location: {
          lat: 35.28495261,
          lon: -80.84555666,
          __typename: 'GeoPointObject',
        },
        __typename: 'MLSListingGeoObject',
      },
      __typename: 'MLSListingObject',
    },
    order: 1,
    __typename: 'PublicListingComparableObject',
  };
};

export const fakeComparables = (
  numComps: number
): PublicListingComparableFragment[] => {
  return Array.from({ length: numComps }, () => fakeComparable());
};
const fakeListingDocumentUpload = (
  realUpload: PublicListingDocumentUploadFragment
): PublicListingDocumentUploadFragment => {
  return {
    ...realUpload,
    file: {
      fileName: 'mog.pdf',
      url: 'https://uploads.useindigo.com/mog.pdf',
      __typename: 'FileObject',
    },
  };
};

export const fakeListingDocumentUploads = (
  realUploads: PublicListingDocumentUploadFragment[]
): PublicListingDocumentUploadFragment[] => {
  return realUploads.map(fakeListingDocumentUpload);
};

export const fakeListingPreferenceValues =
  (): PublicPreferenceValueFragment[] => {
    const now = dayjs();

    return [
      {
        preference: {
          id: '2',
          name: 'Ideal move out date',
          slug: 'ideal_move_out_date',
          usStates: [UsState.GLOBAL],
          valueType: PreferenceValueType.DATE,
        },
        dateTimeValue: now.add(1, 'month').toDate(),
        readOnly: false,
      },
    ];
  };
