import {
  FocusLock,
  Icon,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { UserObject } from '@client/graphql/__generated__/types';
import { MessageSquarePlusIcon } from 'lucide-react';
import { AgentChatBubble } from '../../Agent/AgentChatBubble';

export const NotesPopover = ({
  label,
  onChange,
  notes,
  noteUser,
}: {
  label: string;
  onChange: (notes: string | null) => void;
  notes?: string | null;
  noteUser?: Partial<Pick<UserObject, 'fullName' | 'profilePicture'>>;
}) => {
  return (
    <Popover placement="left">
      <PopoverTrigger>
        {notes ? (
          <AgentChatBubble user={noteUser} />
        ) : (
          <Icon
            as={MessageSquarePlusIcon}
            boxSize={6}
            color="cyan.500"
            cursor="pointer"
            // margin to match AgentChatBubble size
            m={1}
          />
        )}
      </PopoverTrigger>
      <Portal>
        <PopoverContent backgroundColor="bg.mainDark">
          <FocusLock>
            <PopoverArrow backgroundColor="bg.mainDark" />
            <PopoverCloseButton color="whiteAlpha.600" />
            <PopoverHeader>
              <Text
                color="whiteAlpha.600"
                fontSize="sm"
                fontWeight="medium"
                textTransform="uppercase"
              >
                {label} Notes
              </Text>
            </PopoverHeader>
            <PopoverBody>
              <Textarea
                value={notes || ''}
                onChange={(e) => onChange(e.target.value)}
                onClick={(e) => e.stopPropagation()}
                onMouseDown={(e) => e.stopPropagation()}
              />
            </PopoverBody>
          </FocusLock>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
