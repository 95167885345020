import { debounce } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { MapRef } from 'react-map-gl';
import { useIsMobile } from '~/common/hooks/useIsMobile';

export const usePanel = (mapRef: React.RefObject<MapRef>) => {
  const isMobile = useIsMobile();
  const [panelMinSizes, setPanelMinSizes] = useState([30, 20]);
  const [isRightPanelLg, setIsRightPanelLg] = useState(false);
  const debounceResize = useMemo(
    () =>
      debounce(() => {
        window.dispatchEvent(new Event('resize'));
      }, 10),
    []
  );

  const onLayout = useCallback(
    ([left, right]: [number, number]) => {
      if (right === panelMinSizes[1] || left === panelMinSizes[0]) {
        setTimeout(() => {
          mapRef.current?.resize();
        }, 100);
      }

      if ((right / 100) * window.innerWidth > 1100) {
        setIsRightPanelLg(true);
      } else {
        setIsRightPanelLg(false);
      }

      debounceResize();
      mapRef.current?.resize();
    },
    [mapRef, panelMinSizes, debounceResize]
  );

  useEffect(() => {
    const calculatePanelSizes = () => {
      const width = window.innerWidth;
      const rightPanelMinWidth = 600;
      const leftPanelMinWidth = 450;
      const rightPanelMinSize = Math.floor((rightPanelMinWidth / width) * 100);
      const leftPanelMinSize = Math.ceil((leftPanelMinWidth / width) * 100);

      if (isMobile) {
        setPanelMinSizes([20, 40]);
      } else {
        setPanelMinSizes([leftPanelMinSize, rightPanelMinSize]);
      }
    };

    const onResize = () => {
      calculatePanelSizes();
    };

    window.addEventListener('resize', onResize);

    calculatePanelSizes();

    return () => window.removeEventListener('resize', onResize);
  }, [mapRef, isMobile]);

  return {
    panelMinSizes,
    setPanelMinSizes,
    isRightPanelLg,
    onLayout,
    isMobile,
  };
};
