import {
  Flex,
  Heading,
  Icon,
  Image,
  Skeleton,
  Text,
  VStack,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { BathIcon, BedSingleIcon, CalendarIcon, RulerIcon } from 'lucide-react';
import {
  DEFAULT_PROPERTY_IMAGE_URL,
  getPropertySource,
} from '~/apps/consumer/utils/listing.utils';
import { formatToCurrency } from '~/services/document/utils/number';
import { useBuyerOfferAnalysisContext } from '../Context/BuyerOfferAnalysisContext';

export const ListingInfo = () => {
  const { listing } = useBuyerOfferAnalysisContext();

  const listPrice = formatToCurrency(listing?.mlsListing?.listPrice, 0);

  const daysOnMarket =
    listing?.mlsListing?.listDate &&
    dayjs().diff(dayjs(listing?.mlsListing?.listDate), 'days');

  let card = (
    <Flex direction="column" gap={4} height="250px" p={5} width="100%">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        overflow="hidden"
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        width="100%"
      >
        <Heading color="whiteAlpha.600" fontSize="sm" size="smallUppercase">
          Interested Home
        </Heading>
        {/* TODO: Add back in once we handle edting buyer on buyer offer analysis page */}
        {/* <Icon as={PencilIcon} cursor="pointer" /> */}
      </Flex>
      <VStack alignItems="center">
        <Image
          borderRadius="xs"
          fallbackSrc={DEFAULT_PROPERTY_IMAGE_URL}
          height="60px"
          src={getPropertySource(listing?.mlsListing?.photos[0])}
          width="100px"
        />
        <Flex alignItems="center" direction="column" gap={0}>
          <Text fontWeight="medium" size="lg">
            {listPrice}
          </Text>
          <Text color="whiteAlpha.700">
            {listing?.mlsListing?.address.full}{' '}
          </Text>
          <Text color="whiteAlpha.700">
            {listing?.mlsListing?.address.city}{' '}
            {listing?.mlsListing?.address.state}{' '}
            {listing?.mlsListing?.address.postalCode}
          </Text>
          <Flex
            gap={2}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {!!listing?.mlsListing?.property?.bedrooms && (
              <Flex alignItems="center" gap={1}>
                <Icon as={BedSingleIcon} color="whiteAlpha.700" />
                <Text color="whiteAlpha.700" fontSize="sm">
                  {listing?.mlsListing?.property?.bedrooms}
                </Text>
              </Flex>
            )}
            {!!listing?.mlsListing?.property?.bathrooms && (
              <Flex alignItems="center" gap={1}>
                <Icon as={BathIcon} color="whiteAlpha.700" />
                <Text color="whiteAlpha.700" fontSize="sm">
                  {listing?.mlsListing?.property?.bathrooms}
                </Text>
              </Flex>
            )}
            {!!listing?.mlsListing?.property?.area && (
              <Flex alignItems="center" gap={1}>
                <Icon as={RulerIcon} color="whiteAlpha.700" />
                <Text color="whiteAlpha.700" fontSize="sm">
                  {listing?.mlsListing?.property?.area}
                  {' sqft'}
                </Text>
              </Flex>
            )}
            {!!daysOnMarket && (
              <Flex alignItems="center" gap={1}>
                <Icon as={CalendarIcon} color="whiteAlpha.700" />
                <Text color="whiteAlpha.700" fontSize="sm">
                  {daysOnMarket}
                  {' days'}
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </VStack>
    </Flex>
  );

  if (!listing) {
    card = (
      <Skeleton flexBasis={0} flexGrow={1}>
        {card}
      </Skeleton>
    );
  }

  return card;
};
