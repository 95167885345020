import {
  ListingComparableSetFragment,
  MlsListingFragment,
} from '@client/graphql/__generated__/types';
import { useSortable } from '@dnd-kit/sortable';
import { ComparableColumn } from './ComparableColumn';

interface SortableColumnProps {
  mlsListing: MlsListingFragment;
  mainMlsListing?: MlsListingFragment;
  isEditMode?: boolean;
  isMainListing?: boolean;
  onDelete?: (mlsListing: MlsListingFragment) => void;
  showAdjustments?: boolean;
  comparableSet?: ListingComparableSetFragment;
  showNotes?: boolean;
  onAdjustmentsUpdate?: () => Promise<void> | void;
}

export const SortableColumn = ({
  comparableSet,
  mlsListing,
  mainMlsListing,
  isEditMode,
  isMainListing,
  onDelete,
  showAdjustments,
  showNotes,
  onAdjustmentsUpdate,
}: SortableColumnProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: mlsListing.id });

  return (
    <ComparableColumn
      ref={setNodeRef}
      attributes={attributes}
      comparableSet={comparableSet}
      isEditMode={isEditMode}
      isMainListing={isMainListing}
      listeners={listeners}
      mainMlsListing={mainMlsListing}
      mlsListing={mlsListing}
      showAdjustments={showAdjustments}
      showNotes={showNotes}
      transform={transform}
      transition={transition}
      onAdjustmentsUpdate={onAdjustmentsUpdate}
      onDelete={onDelete}
    />
  );
};
